import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Free = () => {
  return (
    <>
      <body class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="734"
          class="elementor elementor-734"
        >
          <div
            class="elementor-element elementor-element-cbe1131 e-flex e-con-boxed  e-con e-parent"
            data-id="cbe1131"
            data-element_type="container"
            data-settings='{"background_background":"classic","animation":"fadeIn","shape_divider_bottom":"opacity-fan"}'
          >
            <div class="e-con-inner">
              <div
                class="elementor-shape elementor-shape-bottom"
                data-negative="false"
              ></div>
              <div
                class="elementor-element elementor-element-f1be5c9 e-con-full e-flex e-con e-child"
                data-id="f1be5c9"
                data-element_type="container"
              >
                <div
                  class="elementor-element elementor-element-c59965f  elementor-widget elementor-widget-heading"
                  data-id="c59965f"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      We design creative digital solutions
                    </h1>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-7439aa8  elementor-widget elementor-widget-text-editor"
                  data-id="7439aa8"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo adipiscing elit.
                    </p>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-cf7230f elementor-align-center  elementor-widget elementor-widget-button"
                  data-id="cf7230f"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <a
                        class="elementor-button elementor-button-link elementor-size-sm"
                        href="#"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-icon">
                            <i
                              aria-hidden="true"
                              class="fas fa-arrow-right"
                            ></i>
                          </span>
                          <span class="elementor-button-text">Contact Us</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-element elementor-element-46c983f e-flex e-con-boxed  e-con e-parent"
            data-id="46c983f"
            data-element_type="container"
            data-settings='{"background_background":"classic","animation":"fadeIn"}'
          >
            <div class="e-con-inner">
              <div
                class="elementor-element elementor-element-8b24df7 e-con-full e-flex e-con e-child"
                data-id="8b24df7"
                data-element_type="container"
              >
                <div
                  class="elementor-element elementor-element-24755dd elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                  data-id="24755dd"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="icon-list.default"
                >
                  <div class="elementor-widget-container">
                    <ul class="elementor-icon-list-items elementor-inline-items">
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">
                          Creative people
                        </span>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">
                          good reviews
                        </span>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">
                          awesome design
                        </span>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">
                          fast delivery
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Free;
