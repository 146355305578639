import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./claim.css";
import Navv from "./Navv";

// const CustomProgressBar = ({ totalSteps, currentStep }) => {
//   const progress = (currentStep / totalSteps) * 100;

//   const progressBarStyle = {
//     width: `${progress}%`,
//   };

//   return (
//     <div className="custom-progress-bar">
//       <div className="progress" style={progressBarStyle}>
//         <span>{progress.toFixed(2)}%</span>
//       </div>
//     </div>
//   );
// };

const Claim = () => {
  const questions = [
    "Enter your name",
    "Enter your email address",
    "What do you sell?",
    "Please list them?",
    "How much is your monthly marketing budget",
    "Website URL.",
    "describe here",
    "Amount",
    "Have you run any online advertising campaigns?",
    "Have you?",
  ];
  const questions2 = [
    "  Okay so we will make this quick and painless. But before we start we don't want to be rude, so whats your name?",
    "Awesome, what is your email?",
    "Okay thanks for that, to find out if Adzenon is a good fit for helping you grow yout business, we've got a few question, first, what do you sell",
    "Okay great what type of marketing are you curently using?",
    "How much is your monthly marketing budget",
    "Website URL. If you don't have one, type “don't have one”",
    "Briefly describe your business",
    "How much revenue a month is your business making?",
    "Have you run any online advertising campaigns?",
    "Have you implemented any specific search engine optimization?",
  ];

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNext = () => {
    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      // Navigate to another page when the last question is reached
      navigate("/contact");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Navv />
      <div class="infusion-field" style={{ marginTop: "100px" }}>
        <div class="book-last-step ">
          <ul className="progressbar">
            {Array.from({ length: questions.length }, (_, index) => (
              <li key={index} className={index <= step ? "active" : ""}></li>
            ))}
          </ul>

          {/* <div class="col-12 text-center  ">
          <span
            class="h3"
            style={{
              fontWeight: "1200",
              fontSize: "40px",
              width: "70%",
              textAlign: "center",
              textTransform: "none",
              margin: "auto",
            }}
          >
            Okay so we will make this quick and painless. But before we start we
            don't want to be rude, so whats your name?
          </span>
        </div>*/}

          <div className="col-12 text-center  ">
            <span
              className="h3"
              style={{
                fontWeight: "1200",
                fontSize: "40px",
                width: "70%",
                textAlign: "center",
                textTransform: "none",
                margin: "auto",
              }}
            >
              {questions2[step]}
            </span>
          </div>
          <p
            style={{
              width: "70%",
              textAlign: "center",
              marginTop: "50px",
              paddingTop: "50px",
              paddingBottom: "50px",
              margin: "auto",
            }}
          ></p>

          <div class="col-12">
            <small class="d-block d-md-none "></small>
            <div>
              <form
                onSubmit={handleSubmit}
                className="infusion-form round-form js-hero-multistep"
                id="hero-multistep"
              >
                <div
                  className="sliding-element 
           
          active"
                >
                  <input
                    className="infusion-field-input-containers"
                    type="text"
                    name={`question-${step}`}
                    placeholder={questions[step]}
                    value={formData[`question-${step}`] || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <button type="button" onClick={handleNext}>
                  {step < questions.length - 1 ? "Next" : "Submit"}
                </button>

                <input
                  type="hidden"
                  name="inf_field_LeadSourceId"
                  value="direct-none-link"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Claim;
