import React from "react";
import Footer from "./Footer";

const Work = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="431"
        class="elementor elementor-431"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-0de0e6d elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
          data-id="0de0e6d"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ed08f3e"
              data-id="ed08f3e"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-6a67091 elementor-widget elementor-widget-heading"
                  data-id="6a67091"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Our Works
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-65c377c elementor-widget elementor-widget-text-editor"
                  data-id="65c377c"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-318c636 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="318c636"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7f4e5b5"
              data-id="7f4e5b5"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-6154405 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="6154405"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f49ce5d"
                      data-id="f49ce5d"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-66eb09e  elementor-widget elementor-widget-heading"
                          data-id="66eb09e"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Portfolios
                            </h2>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-19d2571"
                      data-id="19d2571"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-9b0725c  elementor-widget elementor-widget-text-editor"
                          data-id="9b0725c"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Ut elit tellus, luctus nec ullamcorper
                              mattis, pulvinar dapibus leo.
                            </p>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-28e6455 elementor-align-left  elementor-widget elementor-widget-button"
                          data-id="28e6455"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a
                                class="elementor-button elementor-button-link elementor-size-sm"
                                href="#"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-icon elementor-align-icon-right">
                                    <i
                                      aria-hidden="true"
                                      class="fas fa-arrow-right"
                                    ></i>{" "}
                                  </span>
                                  <span class="elementor-button-text">
                                    Get Started
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-b04e9a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="b04e9a0"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-126b5f1"
                      data-id="126b5f1"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-6fba919 elementor-position-top elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                          data-id="6fba919"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  width="600"
                                  height="750"
                                  src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/22-1.jpg"
                                  class="attachment-full size-full wp-image-229"
                                  alt=""
                                  loading="lazy"
                                  srcset="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/22-1.jpg 600w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/22-1-240x300.jpg 240w"
                                  sizes="(max-width: 600px) 100vw, 600px"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  Dashboard Kit
                                </h5>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-3ffdb48 elementor-position-top elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                          data-id="3ffdb48"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  width="750"
                                  height="600"
                                  src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/20-1.jpg"
                                  class="attachment-full size-full wp-image-193"
                                  alt=""
                                  loading="lazy"
                                  srcset="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/20-1.jpg 750w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/20-1-300x240.jpg 300w"
                                  sizes="(max-width: 750px) 100vw, 750px"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  Bl*ck men stuff
                                </h5>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5143360"
                      data-id="5143360"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-a7199f9 elementor-position-top elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                          data-id="a7199f9"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  width="750"
                                  height="600"
                                  src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/12.jpg"
                                  class="attachment-full size-full wp-image-166"
                                  alt=""
                                  loading="lazy"
                                  srcset="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/12.jpg 750w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/12-300x240.jpg 300w"
                                  sizes="(max-width: 750px) 100vw, 750px"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  Hexa Magazine
                                </h5>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-4f3a957 elementor-position-top elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                          data-id="4f3a957"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  width="600"
                                  height="750"
                                  src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/11-1.jpg"
                                  class="attachment-full size-full wp-image-165"
                                  alt=""
                                  loading="lazy"
                                  srcset="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/11-1.jpg 600w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/11-1-240x300.jpg 240w"
                                  sizes="(max-width: 600px) 100vw, 600px"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  Lookbook Pro
                                </h5>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6d8fb90"
                      data-id="6d8fb90"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-257b440 elementor-position-top elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                          data-id="257b440"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp","_animation_delay":500}'
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  width="600"
                                  height="750"
                                  src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/19-1.jpg"
                                  class="attachment-full size-full wp-image-186"
                                  alt=""
                                  loading="lazy"
                                  srcset="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/19-1.jpg 600w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/19-1-240x300.jpg 240w"
                                  sizes="(max-width: 600px) 100vw, 600px"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  digital ui kit
                                </h5>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-22bb52d elementor-position-top elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                          data-id="22bb52d"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp","_animation_delay":500}'
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  width="750"
                                  height="600"
                                  src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/23.jpg"
                                  class="attachment-full size-full wp-image-233"
                                  alt=""
                                  loading="lazy"
                                  srcset="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/23.jpg 750w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/23-300x240.jpg 300w"
                                  sizes="(max-width: 750px) 100vw, 750px"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  App Branding
                                </h5>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-1d7a460 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="1d7a460"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7dec54"
              data-id="f7dec54"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-b9b1a20  elementor-widget elementor-widget-heading"
                  data-id="b9b1a20"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      What we do
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-71a5800  elementor-widget elementor-widget-text-editor"
                  data-id="71a5800"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Ut elit tellus, luctus nec ullamcorper. Lorem ipsum dolor
                      sit amet. Ut elit tellus, luctus nec ullamcorper.
                    </p>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-ba9fb2e  elementor-widget elementor-widget-accordion"
                  data-id="ba9fb2e"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="accordion.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-accordion">
                      <div class="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-1951"
                          class="elementor-tab-title"
                          data-tab="1"
                          role="button"
                          aria-controls="elementor-tab-content-1951"
                          aria-expanded="false"
                        >
                          <span
                            class="elementor-accordion-icon elementor-accordion-icon-right"
                            aria-hidden="true"
                          >
                            <span class="elementor-accordion-icon-closed">
                              <i class="icon icon-plus"></i>
                            </span>
                            <span class="elementor-accordion-icon-opened">
                              <i class="icon icon-minus"></i>
                            </span>
                          </span>
                          <a class="elementor-accordion-title" tabindex="0">
                            Branding Strategy
                          </a>
                        </div>
                        <div
                          id="elementor-tab-content-1951"
                          class="elementor-tab-content elementor-clearfix"
                          data-tab="1"
                          role="region"
                          aria-labelledby="elementor-tab-title-1951"
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </div>
                      </div>
                      <div class="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-1952"
                          class="elementor-tab-title"
                          data-tab="2"
                          role="button"
                          aria-controls="elementor-tab-content-1952"
                          aria-expanded="false"
                        >
                          <span
                            class="elementor-accordion-icon elementor-accordion-icon-right"
                            aria-hidden="true"
                          >
                            <span class="elementor-accordion-icon-closed">
                              <i class="icon icon-plus"></i>
                            </span>
                            <span class="elementor-accordion-icon-opened">
                              <i class="icon icon-minus"></i>
                            </span>
                          </span>
                          <a class="elementor-accordion-title" tabindex="0">
                            Ecommerce System
                          </a>
                        </div>
                        <div
                          id="elementor-tab-content-1952"
                          class="elementor-tab-content elementor-clearfix"
                          data-tab="2"
                          role="region"
                          aria-labelledby="elementor-tab-title-1952"
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3b7a97e "
              data-id="3b7a97e"
              data-element_type="column"
              data-settings='{"background_background":"classic","animation":"fadeIn"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-18edbd3 elementor-widget__width-initial  elementor-widget elementor-widget-counter"
                  data-id="18edbd3"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeIn"}'
                  data-widget_type="counter.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-counter">
                      <div class="elementor-counter-number-wrapper">
                        <span class="elementor-counter-number-prefix"></span>
                        <span
                          class="elementor-counter-number"
                          data-duration="2000"
                          data-to-value="2.5"
                          data-from-value="0"
                          data-delimiter=","
                        >
                          0
                        </span>
                        <span class="elementor-counter-number-suffix">k</span>
                      </div>
                      <div class="elementor-counter-title">Projects</div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-a4a1d30 elementor-widget__width-initial  elementor-widget elementor-widget-counter"
                  data-id="a4a1d30"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeIn"}'
                  data-widget_type="counter.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-counter">
                      <div class="elementor-counter-number-wrapper">
                        <span class="elementor-counter-number-prefix"></span>
                        <span
                          class="elementor-counter-number"
                          data-duration="2000"
                          data-to-value="725"
                          data-from-value="0"
                          data-delimiter=","
                        >
                          0
                        </span>
                        <span class="elementor-counter-number-suffix"></span>
                      </div>
                      <div class="elementor-counter-title">Clients</div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-91d5365 elementor-widget__width-initial  elementor-widget elementor-widget-counter"
                  data-id="91d5365"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeIn"}'
                  data-widget_type="counter.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-counter">
                      <div class="elementor-counter-number-wrapper">
                        <span class="elementor-counter-number-prefix"></span>
                        <span
                          class="elementor-counter-number"
                          data-duration="2000"
                          data-to-value="32"
                          data-from-value="0"
                          data-delimiter=","
                        >
                          0
                        </span>
                        <span class="elementor-counter-number-suffix"></span>
                      </div>
                      <div class="elementor-counter-title">Countries</div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-e35145a elementor-widget__width-initial  elementor-widget elementor-widget-counter"
                  data-id="e35145a"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeIn"}'
                  data-widget_type="counter.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-counter">
                      <div class="elementor-counter-number-wrapper">
                        <span class="elementor-counter-number-prefix"></span>
                        <span
                          class="elementor-counter-number"
                          data-duration="2000"
                          data-to-value="24"
                          data-from-value="0"
                          data-delimiter=","
                        >
                          0
                        </span>
                        <span class="elementor-counter-number-suffix"></span>
                      </div>
                      <div class="elementor-counter-title">Creatives</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Work;
