import React from "react";
import Footer from "./Footer";
import Okay from "./Okay";
import useFetch from "./hooks/useFetch";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./Blog.css";
import { Editor, EditorState, ContentState } from "draft-js";
import DOMPurify from "dompurify";
const BlogBan = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { data, loading, error } = useFetch(`/properties/find/${id}`);
  const { datePosted } = data; // Assuming you have access to datePosted from your data object
  const formattedDate = new Date(datePosted).toLocaleDateString(); // Format the date
  return (
    <div class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
      <div
        data-elementor-type="wp-post"
        data-elementor-id="401"
        class="elementor elementor-401"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-5574866 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
          data-id="5574866"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-689ba33"
              data-id="689ba33"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-adb6532  elementor-widget elementor-widget-heading"
                  data-id="adb6532"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h3 class="elementor-heading-title elementor-size-default">
                      {data?.title}{" "}
                    </h3>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-bd58def  elementor-widget elementor-widget-text-editor"
                  data-id="bd58def"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <span style={{ color: "yellow" }}>Posted</span>{" "}
                    {formattedDate}
                    <span style={{ color: "yellow" }}>By</span>{" "}
                    {data?.authorName}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <br></br>

        <br></br>
        <div class="elementor-widget-container">
          {data.photos?.map((photo, i) => (
            <div key={i}>
              <img src={photo} alt="" className="new" />
            </div>
          ))}
        </div>
        <div
          class="elementor-widget-container"
          style={{ textAlign: "Justify", width: "80%", margin: "auto" }}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.desc1),
            }}
          ></p>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default BlogBan;
