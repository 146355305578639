// ParentTable.js
import React, { useState } from "react";
import "./Land.css";
import Navv from "./Navv";
import trust3 from "./image/hull2.png";
import trust4 from "./image/oneTree.jpeg";
import trust5 from "./image/bbc2.png";
import trust6 from "./image/bon.png";
import pilot from "./pilot.png";
import back from "./image/5.JPG";
import { useNavigate } from "react-router-dom";
const Land = () => {
  const questions = ["Enter your email address...", "Enter your name..."];

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNext = () => {
    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      // Navigate to another page when the last question is reached
      navigate("/claim");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check if all required fields are filled in
      if (formData["question-0"] && formData["question-1"]) {
        // Make a POST request to your backend endpoint
        const response = await fetch(
          "https://adzenon-bd1b18e42724.herokuapp.com/api/post/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: formData["question-0"],
              name: formData["question-1"],
              // Add other fields as needed
            }),
          }
        );

        // Check if the request was successful
        if (response.ok) {
          console.log("User data saved successfully!");
          // Redirect or perform any other actions after successful submission
          navigate("/success");
        } else {
          console.error("Error saving user data:", response.statusText);
          // Handle error scenarios
        }
      } else {
        console.error("All required fields must be filled in.");
      }
    } catch (error) {
      console.error("Error saving user data:", error.message);
      // Handle error scenarios
    }
  };

  return (
    <>
      <Navv />
      <div>
        <main class="site-contents">
          <section class="text-light   content-wysiwygs content-textarea content-code next-has-stripes ">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>{" "}
            <div class="background-image-containers">
              <div style={{ backgroundColor: "#0a0404" }}>
                <img src={back} style={{ opacity: "0.15" }} />
              </div>
            </div>
            <video
              class="video-bg"
              loop
              muted
              autoplay
              playsinline
              style={{ opacity: "0.15" }}
            >
              <source
                src="wp-content/uploads/sites/4/2021/05/hero-bg-video-2021.mp4"
                type="video/mp4"
              />
            </video>
            <div
              class="containers"
              style={{
                margin: "auto",

                paddingTop: "40px",
                lineHeight: "1.2",
              }}
            >
              <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                  <p
                    class="h2"
                    style={{
                      textAlign: "center",
                      fontSize: " 5.4rem",
                      marginBottom: "3rem",
                    }}
                  >
                    We are<sup>*</sup>
                    <br />
                    Adzenon
                  </p>
                </div>

                <div class="col-12 col-md-10 col-lg-9 col-xl-8 mb-5 text-center">
                  <p
                    class="mb-10"
                    style={{ fontWeight: "400", fontSize: "2rem" }}
                  >
                    Unlock Exponential Growth and Revolutionise your Brand's
                    Impact and Sales with our Free 30-Minute Sustainable Scaling
                    Session.
                  </p>

                  <div class="col-12">
                    <small class="d-block d-md-none "></small>
                    <div>
                      <form
                        onSubmit={handleSubmit}
                        className="infusion-form round-form js-hero-multistep"
                        id="hero-multistep"
                      >
                        <div
                          className="sliding-element 
                         
                        active"
                        >
                          <input
                            className="infusion-field-input-containers"
                            type="text"
                            name={`question-${step}`}
                            placeholder={questions[step]}
                            value={formData[`question-${step}`] || ""}
                            onChange={handleInputChange}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={
                            step < questions.length - 1
                              ? handleNext
                              : handleSubmit
                          }
                        >
                          {step < questions.length - 1 ? "Next" : "Submit"}
                        </button>

                        <input
                          type="hidden"
                          name="inf_field_LeadSourceId"
                          value="direct-none-link"
                        />
                      </form>
                    </div>
                  </div>

                  <div class="cards">
                    <div class="cards-content">
                      <section>
                        <div class="containers">
                          <div class="row justify-content-center">
                            <div class=" " style={{ display: "flex" }}>
                              <div>
                                <img
                                  width="150"
                                  height="43"
                                  src={pilot}
                                  class="attachment-container_width size-container_width"
                                  alt
                                />
                              </div>

                              <div class="texts-container">
                                <div>
                                  <b>
                                    <p
                                      class="cards-text"
                                      style={{
                                        paddingLeft: "20px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      4x Higher CTRSs
                                    </p>
                                  </b>
                                </div>
                                <div>
                                  <b>
                                    <p
                                      class="cards-text"
                                      style={{
                                        fontSize: "10px",
                                      }}
                                    >
                                      6.9x Higher Engagement
                                    </p>
                                  </b>
                                </div>
                                <div>
                                  <b>
                                    <p
                                      class="cards-text"
                                      style={{
                                        fontSize: "10px",
                                        width: "100% !important",
                                      }}
                                    >
                                      4.56 Average ROAS
                                    </p>
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="text-dark   content-wysiwygs next-has-stripes has-stripes"
            style={{ marginTop: "150px" }}
          >
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>{" "}
            <div class="background-image-containers"></div>
            <div class="containers">
              <div class="row justify-content-center">
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    <strong class="date-updated">
                      Dear Champion of Sustainability,
                    </strong>
                  </p>
                  <p>
                    We understand the weight that rests on your shoulders, the
                    sleepless nights, the relentless pursuit of balance between
                    profit and purpose.
                  </p>
                  <p>
                    It's not just a business to you; it's a dream, a vision of a
                    better, more sustainable world.{" "}
                  </p>
                  <p>
                    But dear friend, we implore you to take a moment for
                    yourself.
                  </p>
                  <p>
                    Imagine a world where those sleepless nights turn into
                    peaceful slumber, where the balance between profit and
                    purpose is not a constant struggle, but a harmonious dance.
                  </p>
                  <p>
                    Picture a path paved with clarity, where your vision for a
                    thriving, eco-conscious brand becomes not just a
                    possibility, but a reality.
                  </p>
                  <p>
                    This is not just a promise, but a guarantee. With our
                    tailored strategies, we will unveil a roadmap that caters to
                    the unique heartbeat of your brand.
                  </p>
                  <p>
                    Through our unique proprietary system, we harness the power
                    of innovative and creative strategies, staying ahead of the
                    curve with the latest trends, and employing AI-driven
                    techniques
                  </p>
                  <p>This is not just strategy; it's a revolution.</p>
                  <p>
                    t's about more than just profitability; it's about creating
                    a legacy of positive change, one that leaves a lasting mark
                    on this planet we call home.
                  </p>

                  <p>
                    We invite you to take that first step, to breathe easier, to
                    dream bigger, and to believe in a future where your business
                    not only survives but thrives.
                  </p>
                  <p>
                    Book your free strategy session with us, and let's embark on
                    this journey together.
                  </p>
                  <p>
                    You deserve this transformation, it's within your reach.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="text-dark section-globe  content-text content-textarea content-globe ">
            <div class="background-image-containers"></div>
            <div class="containers">
              <div class="row justify-content-center">
                <div class="col-lg-12 text-center text-center ">
                  <span
                    class="h3"
                    style={{ fontWeight: "700", fontSize: "40px" }}
                  >
                    What is inside?
                  </span>
                </div>

                <div class="row mx-0 pb-0">
                  <div class="col-md-6 col-lg-3">
                    <div class="card  delay-1">
                      <p class="h6">
                        <b> Tailored Strategies for Sustainable Brands </b>
                      </p>

                      <small>
                        Unleash the power of strategies meticulously designed
                        for brands committed to sustainability. From
                        eco-conscious startups to established green enterprises,
                        our approach is customised to amplify your impact while
                        maximising profitability
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <div class="card delay-2">
                      <p class="h6">
                        <b> The Perfect Harmony of Profitability and Purpose</b>
                      </p>

                      <small>
                        Understand Your Target Audience Fully. Our Paid Social
                        Experience a groundbreaking blend of financial success
                        and meaningful impact. Our experts will guide you in
                        striking the ideal balance, ensuring your brand not only
                        thrives in the market but also leaves a positive mark on
                        the environment.
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <div class="card delay-3">
                      <p class="h6">
                        <b>Data-Driven Insights to Propel Your Brand</b>
                      </p>

                      <small>
                        Gain access to a treasure trove of data-driven insights
                        that will revolutionise your brand's trajectory. We
                        provide you with the tools and knowledge to make
                        informed decisions that drive sustainable growth and
                        lasting success
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <div class="card  delay-4">
                      <p class="h6">
                        <b>Expert Guidance and Cutting-Edge Research</b>
                      </p>

                      <small>
                        Tap into a wealth of expertise and stay ahead of the
                        curve with our industry-leading research. Our team is
                        dedicated to keeping you at the forefront of sustainable
                        business practices, ensuring your brand not only
                        survives but thrives in today's ever-changing landscape.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <p
            class="eyebrow"
            style={{
              textAlign: "center",
              width: "70%",
              margin: "auto",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            So what are you waiting for? Book your Free 30-Minute Sustainable
            Scaling Session today and let's revolutionise your brand's success
            story!
          </p>
          <div class="col-12">
            <small class="d-block d-md-none text-center">
              Enter your email here and we'll send you our free e-book
            </small>
            <form
              className="infusion-form round-form js-hero-multistep"
              id="hero-multistep"
            >
              <div
                className="sliding-element 
             
            active"
              >
                <input
                  className="infusion-field-input-containers"
                  type="text"
                  name={`question-${step}`}
                  placeholder={questions[step]}
                  value={formData[`question-${step}`] || ""}
                  onChange={handleInputChange}
                />
              </div>
              <button type="button" onClick={handleNext}>
                {step < questions.length - 1 ? "Next" : "Submit"}
              </button>

              <input
                type="hidden"
                name="inf_field_LeadSourceId"
                value="direct-none-link"
              />
            </form>
          </div>

          <section class="text-dark   content-text content-accordion ">
            <div class="background-image-containers"></div>
            <div class="containers">
              <div class="row justify-content-center">
                <div class="col-12 text-center">
                  <span
                    class="h2"
                    style={{ fontWeight: "700", fontSize: "30px" }}
                  >
                    Frequently Asked Questions
                  </span>
                </div>
                <div class="col-lg-10">
                  <dl class="accordions">
                    <div
                      class="accordion"
                      itemscope
                      itemprop="mainEntity"
                      itemtype="https://schema.org/Question"
                    >
                      <dt class=" accordion__intro">
                        <h5 style={{ fontSize: "20px" }}>
                          What is our company primary mission?
                        </h5>
                      </dt>
                      <dd
                        class="accordion__content"
                        itemscope
                        itemprop="acceptedAnswer"
                        itemtype="https://schema.org/Answer"
                      >
                        <div class="accordion__text" itemprop="text">
                          <p>
                            A good <strong>digital marketing agency</strong>{" "}
                            will get to know your business inside out. They will
                            assess the traffic that your website is getting and
                            establish the best online marketing platforms to
                            invest in. Part of a digital marketing
                            agency&rsquo;s strategy should involve continual
                            evaluation to ensure a client is maintaining the
                            perfect balance between their marketing spend and
                            the results they&rsquo;re getting.
                          </p>
                          <p>
                            We do all of that but, unlike other agencies, the
                            King Kong team isn&rsquo;t just focused on
                            maintaining that perfect balance. Our goal is to
                            crush your competitors, turn website visitors into
                            buyers and bring you the biggest ROI possible using
                            the most effective marketing strategies available.
                          </p>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="accordion"
                      itemscope
                      itemprop="mainEntity"
                      itemtype="https://schema.org/Question"
                    >
                      <dt
                        class=" accordion__intro"
                        style={{ fontSize: "20px" }}
                      >
                        What Is The Minimum Contract Term?
                      </dt>
                      <dd
                        class="accordion__content"
                        itemscope
                        itemprop="acceptedAnswer"
                        itemtype="https://schema.org/Answer"
                      >
                        <div class="accordion__text" itemprop="text">
                          <p>
                            Because we put our money where our mouth is and we
                            make <em>BIG guarantees</em> that other marketing
                            agencies don&rsquo;t. Go check out our individual
                            service pages for more information on what
                            guarantees we offer.
                          </p>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="accordion"
                      itemscope
                      itemprop="mainEntity"
                      itemtype="https://schema.org/Question"
                    >
                      <dt
                        class=" accordion__intro"
                        style={{ fontSize: "20px" }}
                      >
                        Is Adzenon Sustainable
                      </dt>
                      <dd
                        class="accordion__content"
                        itemscope
                        itemprop="acceptedAnswer"
                        itemtype="https://schema.org/Answer"
                      >
                        <div class="accordion__text" itemprop="text">
                          <p>
                            Are you serious about scaling your operations and
                            dominating your market space? We can work with you.
                          </p>
                          <p>
                            Do you want to plug into a team of marketers,{" "}
                            <strong>digital strategists</strong>, designers, and
                            developers who will help you to smash your growth
                            goals and make the kind of ROI that will leave you
                            shouting from the rooftops? We can work with you.
                          </p>
                          <p>
                            Do you understand the value of spending $1 to make
                            $5? We can work with you.
                          </p>
                          <p>
                            It doesn&rsquo;t matter if you&rsquo;re a big or
                            small company. Our clients range from small, local
                            businesses with 5 or less employees to large
                            multinational companies with a workforce of hundreds
                            of people operating from all corners of the globe.
                          </p>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="accordion"
                      itemscope
                      itemprop="mainEntity"
                      itemtype="https://schema.org/Question"
                    >
                      <dt class=" accordion__intro">
                        <h2 style={{ fontSize: "20px" }}>
                          Do we need to pay for creatives separately?
                        </h2>{" "}
                      </dt>
                      <dd
                        class="accordion__content"
                        itemscope
                        itemprop="acceptedAnswer"
                        itemtype="https://schema.org/Answer"
                      >
                        <div class="accordion__text" itemprop="text">
                          <p>
                            Why are burritos delicious? Why is water wet? No,
                            but seriously. Instead of using this little section
                            of content to spam you with humblebrags, let us just
                            say this&#8230; Marketers lie, numbers don&rsquo;t.
                          </p>
                          <p>
                            So instead of trying to convince you, we&rsquo;re
                            the best digital marketing agency around&#8230;here
                            are the stats:
                          </p>
                          <ul>
                            <li>$7.8 billion in sales generated.</li>
                            <li>1067 different industries and niches.</li>
                            <li>4,952 reviews (average of 4.7 stars)</li>
                            <li>38 millionaires created.</li>
                            <li>6x 8 figure clients created.</li>
                            <li>
                              #17 fastest growing company in Australia (ranked
                              by the AFR)
                            </li>
                            <li>
                              #1 fastest growing agency (3 years in a row)
                            </li>
                          </ul>
                          <p>
                            You see, most digital marketing agencies talk a good
                            game, but when is all said and done, they
                            don&rsquo;t use the very same internet marketing
                            services they prescribe to their clients (digital
                            marketing, SEO, Facebook ads and sales funnel etc)
                            to grow their own business.
                          </p>
                          <p>
                            Which is HUGE telltale sign to run for the hills!
                          </p>
                          <p>
                            Anyhoo. If you&rsquo;re looking for a digital
                            marketing company that&rsquo;s worth its
                            weight&#8230;You should go{" "}
                            <a
                              href="https://kingkong.co/reviews/"
                              target="_blank"
                              rel="noopener"
                            >
                              check out our reviews here
                            </a>{" "}
                            and see all the nice things our clients have said
                            about the work we do.
                          </p>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="accordion"
                      itemscope
                      itemprop="mainEntity"
                      itemtype="https://schema.org/Question"
                    >
                      <dt
                        class=" accordion__intro"
                        style={{ fontSize: "20px" }}
                      >
                        How does sustainability factor into your services?
                      </dt>
                      <dd
                        class="accordion__content"
                        itemscope
                        itemprop="acceptedAnswer"
                        itemtype="https://schema.org/Answer"
                      >
                        <div class="accordion__text" itemprop="text">
                          <p>
                            We offer guarantees for all of our traffic sources.
                            Whether it&rsquo;s{" "}
                            <a href="https://kingkong.co/ppc-management-agency/">
                              Google Ads
                            </a>
                            ,{" "}
                            <a href="https://kingkong.co/facebook-advertising-agency/">
                              Facebook marketing
                            </a>
                            , or{" "}
                            <a href="https://kingkong.co/seo-agency/">SEO</a>,
                            we put our money where our mouth is; if we
                            don&rsquo;t hit our agreed-upon targets, we
                            don&rsquo;t get paid.
                          </p>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="accordion"
                      itemscope
                      itemprop="mainEntity"
                      itemtype="https://schema.org/Question"
                    >
                      <dt class=" accordion__intro">
                        <h2 style={{ fontSize: "20px" }}>
                          What is user-generated content?
                        </h2>{" "}
                      </dt>
                      <dd
                        class="accordion__content"
                        itemscope
                        itemprop="acceptedAnswer"
                        itemtype="https://schema.org/Answer"
                      >
                        <div class="accordion__text" itemprop="text">
                          <p>
                            This is like asking &ldquo;how long is a piece of
                            string&rdquo; as there is no right answer. The
                            answer depends on YOUR BUSINESS. We treat every
                            business differently because no two businesses
                            &#8211; even if they operate in the same industry
                            &#8211; are the same. Let&rsquo;s apply that logic
                            to your business and that of your biggest
                            competitor. Yes, you&rsquo;re targeting the same
                            audience and you offer a similar product or service
                            but that&rsquo;s it. Their website is developed
                            differently to yours. It could be older/younger than
                            your site. They might have more/less backlinks and
                            they might have better/worse conversion rates
                            compared to your site. A strategy that involves an
                            intensive link building and content marketing
                            campaign might be all you need whilst your
                            competitor might make more gains investing in Google
                            Ads. The list of differences go on and on.
                          </p>
                          <p>
                            The key takeaway here is we know that one size DOES
                            NOT fit all, which is why we curate custom
                            strategies for each of our clients to fit their
                            exact needs. Our goal is to skyrocket their online
                            presence into the stratosphere and we&rsquo;ll do
                            whatever it takes to achieve that goal.
                          </p>
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </section>
          <section
            class="text-light   content-global hit-the-damn-button "
            style={{ backgroundColor: "#FFBF00" }}
          >
            <div
              class="background-image-containers"
              style={{ backgroundColor: "0a0a0a" }}
            ></div>
            <div class="containers">
              <div class="row justify-content-center">
                <div
                  class="col-sm-10 col-md-9 col-lg-8 col-xl-7 text-center"
                  style={{ color: "black" }}
                >
                  <span
                    class="h2"
                    style={{ fontSize: "30px", color: "black !important" }}
                  >
                    <p style={{ color: "black" }}>
                      So what are you waiting for? Book your Free 30-Minute
                      Sustainable Scaling Session today and let's revolutionise
                      your brand's success story!
                    </p>
                  </span>
                </div>
                <div
                  class="col-sm-10 col-md-9 col-lg-8 col-xl-7 text-center"
                  style={{ color: "black" }}
                >
                  <span
                    class="h2"
                    style={{ fontSize: "30px", color: "black !important" }}
                  ></span>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer
          class="site-footers"
          id="footer-containers"
          style={{ backgroundColor: "black", width: "100%" }}
        >
          <div class="containers">
            <div class="footer-columns">
              <div class="row justify-content-center">
                <div class="col footer-column-1">
                  <div id="text-2" class="widget widget_text footer-widgets-1">
                    {" "}
                    <div class="textwidget">
                      <p style={{ color: "white" }}>
                        Where marketing meets sustainability. We power brands
                        with purpose for a regenerative future.
                      </p>
                    </div>
                  </div>
                  <div
                    id="kk_address_widget-2"
                    class="widget widget_kk_address_widget footer-widgets-1"
                    style={{ color: "white" }}
                  >
                    2nd Floor, College House, 17 King Edwards Road,
                    <br />
                    Ruislip, London,
                    <br />
                    United Kingdom HA4 7AE
                    <br />
                    Phone: <a href="tel:+448003685476">(+44 7) 311 334436</a>
                  </div>
                  <div
                    id="custom_html-2"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <a
                        href="https://facebook.com/kingkongco/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Facebook"
                      >
                        <svg
                          height="512"
                          viewbox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z" />
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/kingkong.com.au/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Instagram"
                      >
                        <svg
                          height="511pt"
                          viewbox="0 0 511 511.9"
                          width="511pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0" />
                          <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm167.402-222c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0" />
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/kingkong/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our LinkedIn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewbox="0 0 504.4 504.4"
                        >
                          <path d="M377.6.2H126.4C56.8.2 0 57 0 126.6v251.6c0 69.2 56.8 126 126.4 126H378c69.6 0 126.4-56.8 126.4-126.4V126.6C504 57 447.2.2 377.6.2zM168 408.2H96v-208h72v208zm-36.4-240c-20.4 0-36.8-16.4-36.8-36.8s16.4-36.8 36.8-36.8 36.8 16.4 36.8 36.8c-.4 20.4-16.8 36.8-36.8 36.8zm276.8 240H348V307.4c0-24.4-3.2-55.6-36.4-55.6-34 0-39.6 26.4-39.6 54v102.4h-60v-208h56v28h1.6c8.8-16 29.2-28.4 61.2-28.4 66 0 77.6 38 77.6 94.4v114z" />
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCwDi1NwScPpDYyvwPvHBj9g"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Youtube"
                      >
                        <svg
                          height="682pt"
                          viewbox="-21 -117 682.667 682"
                          width="682pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0" />
                        </svg>
                      </a>
                      <a
                        href="https://open.spotify.com/user/12147390775/playlist/6p7sfcY8NgLASvan5kY5KX"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Spotify Playlist"
                      >
                        <svg
                          height="512"
                          viewbox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 24c6.624 0 12-5.376 12-12S18.624 0 12 0 0 5.376 0 12s5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953a.938.938 0 0 1-.938.944zM4.548 6.998c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189C15.605 8.55 9.444 8.057 5.458 9.17c-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <button
                    class="siteswitcher__button"
                    data-js-modal-trigger="swModal"
                  >
                    <img
                      width="35"
                      height="18"
                      src="wp-content/themes/kingkong/assets/prod/images/flag-united-kingdom.svg"
                      alt="Flag of United Kingdom"
                    />
                    <span>United Kingdom</span>
                  </button>
                  <div class="js-modal siteswitcher" data-js-modal-id="swModal">
                    <div class="modal">
                      <div class="js-close close-x">
                        <svg
                          viewBox="0 0 329.269 329"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"></path>
                        </svg>
                      </div>
                      <div class="content">
                        <p class="h3">United Kingdom</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-2">
                  <div
                    id="nav_menu-2"
                    class="widget widget_nav_menu footer-widgets-2"
                  >
                    <p class="widget-title" style={{ color: "white" }}>
                      Menu
                    </p>
                    <div class="menu-company-containers">
                      <ul id="menu-company" class="menu">
                        <li
                          id="menu-item-13414"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13414"
                        >
                          <a href="about-us/index.html">About Us</a>
                        </li>

                        <li
                          id="menu-item-13415"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13415"
                        >
                          <a href="careers/index.html">Services</a>
                        </li>
                        <li
                          id="menu-item-13415"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13415"
                        >
                          <a href="careers/index.html">Blog</a>
                        </li>
                        <li
                          id="menu-item-13415"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13415"
                        >
                          <a href="careers/index.html">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-3">
                  <div
                    id="nav_menu-3"
                    class="widget widget_nav_menu footer-widgets-3"
                  >
                    <p class="widget-title" style={{ color: "white" }}>
                      Services
                    </p>
                    <div class="menu-services-containers">
                      <ul id="menu-services" class="menu">
                        <li
                          id="menu-item-13413"
                          class="footer-seo-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-13413"
                        >
                          <a href="seo-agency/index.html">SEO</a>
                        </li>
                        <li
                          id="menu-item-13413"
                          class="footer-seo-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-13413"
                        >
                          <a href="seo-agency/index.html">SEM</a>
                        </li>

                        <li
                          id="menu-item-13408"
                          class="footer-webdesign-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-13408"
                        >
                          <a href="web-design-agency/index.html">
                            Web Development
                          </a>
                        </li>
                        <li
                          id="menu-item-13408"
                          class="footer-webdesign-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-13408"
                        >
                          <a href="web-design-agency/index.html">
                            App Development
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-4">
                  <div
                    id="nav_menu-4"
                    class="widget widget_nav_menu footer-widgets-4"
                  >
                    <p class="widget-title" style={{ color: "white" }}>
                      Quick Links
                    </p>
                    <div class="menu-solutions-containers">
                      <ul id="menu-solutions" class="menu">
                        <li
                          id="menu-item-13430"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13430"
                        >
                          <a href="agency/index.html">About</a>
                        </li>
                        <li
                          id="menu-item-13430"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13430"
                        >
                          <a href="agency/index.html">Contact</a>
                        </li>
                        <li
                          id="menu-item-13430"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13430"
                        >
                          <a href="agency/index.html">Privacy policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-line text-center">
            <div class="containers">
              <div class="row">
                <div class="col-12">
                  Copyright © Adzenon 2023. All Rights Reserved.
                  <br class="d-none d-md-block" /> Protected by all the possible
                  laws. Dont copy
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Land;
