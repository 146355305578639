// ParentTable.js
import React from "react";
import DuotoneSVG from "./DuotoneSVG";

import "./Style.css";
import hope from "./image/love.png";
import why from "./image/9.JPG";
import pat from "./image/5.JPG";
import pat2 from "./image/6.JPG";
import news from "./image/new5.png";
import sustain from "./image/but.jpg";
import Footer from "./Footer";
import Navv from "./Navv";
import {
  FaArrowRight,
  FaCheck,
  FaShoppingCart,
  FaUserEdit,
  FaLaptop,
  FaSearch,
} from "react-icons/fa";

const About = () => {
  return (
    <>
      <Navv />
      <div class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
        <body class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-459 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-459">
          <div
            data-elementor-type="wp-post"
            data-elementor-id="459"
            class="elementor elementor-459"
          >
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-68aeed1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="68aeed1"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-52a3041"
                  data-id="52a3041"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-2a83a42  elementor-widget elementor-widget-heading"
                      data-id="2a83a42"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInLeft"}'
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h6 class="elementor-heading-title elementor-size-default">
                          About us
                        </h6>{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-2873eea elementor-widget elementor-widget-heading"
                      data-id="2873eea"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h2 class="elementor-heading-title elementor-size-default">
                          <img src={news} style={{}} />
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-3f88a87"
                  data-id="3f88a87"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-87da832 elementor-widget elementor-widget-text-editor"
                      data-id="87da832"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        <p>
                          ADZENON is where creativity meets sustainability,
                          where data fuels innovation, and where collaboration
                          sparks success. We're not just a marketing agency;
                          we're the storytellers of tomorrow's brands, weaving
                          compelling narratives that balance growth with a heart
                          for the planet.
                        </p>{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-ac12de2 elementor-align-left  elementor-widget elementor-widget-button"
                      data-id="ac12de2"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="button.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-button-wrapper">
                          <a
                            class="elementor-button elementor-button-link elementor-size-sm"
                            href="/contact"
                          >
                            <span class="elementor-button-content-wrapper">
                              <span class="elementor-button-icon elementor-align-icon-right">
                                <FaArrowRight />
                              </span>
                              <span class="elementor-button-text">
                                Get started
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-8907f0d elementor-section-boxed elementor-section-height-default elementor-section-height-default "
              data-id="8907f0d"
              data-element_type="section"
              data-settings='{"animation":"fadeIn"}'
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d047920"
                  data-id="d047920"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-0620b6b  elementor-widget elementor-widget-image"
                      data-id="0620b6b"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInLeft"}'
                      data-widget_type="image.default"
                    >
                      <div class="elementor-widget-container">
                        <img
                          decoding="async"
                          width="800"
                          height="800"
                          src={pat}
                          class="attachment-full size-full wp-image-91"
                          alt=""
                          loading="lazy"
                          sizes="(max-width: 800px) 100vw, 800px"
                        />{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-d024800 elementor-widget elementor-widget-image"
                      data-id="d024800"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="image.default"
                    >
                      <div class="elementor-widget-container">
                        <img
                          decoding="async"
                          width="600"
                          height="750"
                          src={pat2}
                          class="attachment-full size-full wp-image-182"
                          alt=""
                          loading="lazy"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-22511be"
                  data-id="22511be"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-18d83dd elementor-widget elementor-widget-heading"
                      data-id="18d83dd"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h5 class="elementor-heading-title elementor-size-default">
                          Why partner with us
                        </h5>{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-9525b11  elementor-widget elementor-widget-text-editor"
                      data-id="9525b11"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        <p>
                          We're on a mission that transcends profit – we're here
                          to foster a regenerative economy. It's a 'why' that
                          drives us every day.
                        </p>
                        <p>
                          We're passionate about turning purpose into profit,
                          making ROI a meaningful part of your success story.
                        </p>
                        <p>
                          We understand that sustainability is a journey, not a
                          destination. And we're here to walk that path with
                          you.
                        </p>
                        <p>
                          Together, we're crafting a narrative where purpose
                          drives profit, where your aspirations become a
                          catalyst for regenerating not just your business but
                          the world around it. Partner with ADZENON, and let's
                          embark on a journey that goes beyond the 'what' and
                          'how,' to uncover the 'why' that truly matters.
                        </p>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-bc41a65 elementor-align-left  elementor-widget elementor-widget-button"
                      data-id="bc41a65"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="button.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-button-wrapper">
                          <a
                            class="elementor-button elementor-button-link elementor-size-sm"
                            href="/contact"
                          >
                            <span class="elementor-button-content-wrapper">
                              <span class="elementor-button-icon elementor-align-icon-right">
                                <FaArrowRight />
                              </span>
                              <span
                                class="elementor-button-text"
                                style={{ color: "black" }}
                              >
                                Let's start
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-09c92a8 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
              data-id="09c92a8"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"fadeIn"}'
            >
              <div class="elementor-background-overlay"></div>
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-839d51e"
                  data-id="839d51e"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-6bd11f2  elementor-widget elementor-widget-heading"
                      data-id="6bd11f2"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h2 class="elementor-heading-title elementor-size-default">
                          Our Mission
                        </h2>{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-af90428  elementor-widget elementor-widget-text-editor"
                      data-id="af90428"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        <p>
                          At ADZENON, our mission is like a spark that ignites
                          not just growth, but transformation. We're here to
                          champion the visionaries, from innovative SMEs to
                          corporate giants deeply committed to sustainability
                        </p>
                        <p>
                          But it doesn't stop there; our journey is fueled by a
                          promise—a promise to become carbon negative by 2024.
                          It's a commitment that goes beyond balance sheets;
                          it's a promise to the planet. We're not just
                          bystanders; we're active supporters of climate-related
                          charities, propelling collective growth and igniting a
                          profound wave of environmental action.
                        </p>
                        <p>
                          This isn't just business; it's a passionate narrative
                          of change, one that invites you to be part of
                          something truly remarkable.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-cc3b56e"
                  data-id="cc3b56e"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-09d8ff9  elementor-widget elementor-widget-image"
                      data-id="09d8ff9"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInRight"}'
                      data-widget_type="image.default"
                    >
                      <div class="elementor-widget-container">
                        <img
                          decoding="async"
                          width="1000"
                          height="1000"
                          src={sustain}
                          class="attachment-full size-full wp-image-20"
                          alt=""
                          loading="lazy"
                          sizes="(max-width: 1000px) 100vw, 1000px"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-db2d8c3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="db2d8c3"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f41c280"
                  data-id="f41c280"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <section
                      class="elementor-section elementor-inner-section elementor-element elementor-element-cc0265c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="cc0265c"
                      data-element_type="section"
                    >
                      <div class="elementor-container elementor-column-gap-default">
                        <div
                          class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9193055"
                          data-id="9193055"
                          data-element_type="column"
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-7a55b24  elementor-widget elementor-widget-heading"
                              data-id="7a55b24"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp"}'
                              data-widget_type="heading.default"
                            >
                              <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default">
                                  Our Services
                                </h2>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-dbef646"
                          data-id="dbef646"
                          data-element_type="column"
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-524ec1e  elementor-widget elementor-widget-text-editor"
                              data-id="524ec1e"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp"}'
                              data-widget_type="text-editor.default"
                            >
                              <div class="elementor-widget-container">
                                <p>
                                  Explore ADZENON’s complete suite of services,
                                  all aimed at enhancing your digital presence
                                  and impact
                                </p>{" "}
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-9c76082 elementor-align-left  elementor-widget elementor-widget-button"
                              data-id="9c76082"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp"}'
                              data-widget_type="button.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-button-wrapper">
                                  <a
                                    class="elementor-button elementor-button-link elementor-size-sm"
                                    href="#"
                                  >
                                    <span class="elementor-button-content-wrapper">
                                      <span class="elementor-button-icon elementor-align-icon-right">
                                        <FaArrowRight />
                                      </span>
                                      <span class="elementor-button-text">
                                        All services
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      class="elementor-section elementor-inner-section elementor-element elementor-element-f34efc7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="f34efc7"
                      data-element_type="section"
                    >
                      <div class="elementor-container elementor-column-gap-default">
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9c839be"
                          data-id="9c839be"
                          data-element_type="column"
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-305bbeb elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                              data-id="305bbeb"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp"}'
                              data-widget_type="icon-box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-box-wrapper">
                                  <div class="elementor-icon-box-icon">
                                    <span class="elementor-icon elementor-animation-grow">
                                      <FaLaptop />
                                    </span>
                                  </div>
                                  <div class="elementor-icon-box-content">
                                    <h5 class="elementor-icon-box-title">
                                      <span>
                                        Sustainable E-commerce Development{" "}
                                      </span>
                                    </h5>
                                    <p class="elementor-icon-box-description">
                                      We craft eco-friendly e-commerce sites and
                                      software solutions using cutting-edge
                                      tech.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-af4f3c0"
                          data-id="af4f3c0"
                          data-element_type="column"
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-0b4fcd2 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                              data-id="0b4fcd2"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                              data-widget_type="icon-box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-box-wrapper">
                                  <div class="elementor-icon-box-icon">
                                    <span class="elementor-icon elementor-animation-grow">
                                      <FaUserEdit />
                                    </span>
                                  </div>
                                  <div class="elementor-icon-box-content">
                                    <h5 class="elementor-icon-box-title">
                                      <span>
                                        User-Generated Content + Paid Social
                                      </span>
                                    </h5>
                                    <p class="elementor-icon-box-description">
                                      Elevate your brand through engaging
                                      content and targeted social ads.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c4749ec"
                          data-id="c4749ec"
                          data-element_type="column"
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-740f8b7 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                              data-id="740f8b7"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp","_animation_delay":500}'
                              data-widget_type="icon-box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-box-wrapper">
                                  <div class="elementor-icon-box-icon">
                                    <span class="elementor-icon elementor-animation-grow">
                                      <FaSearch />
                                    </span>
                                  </div>
                                  <div class="elementor-icon-box-content">
                                    <h5 class="elementor-icon-box-title">
                                      <span>
                                        Search engine marketing + Search engine
                                        optimization{" "}
                                      </span>
                                    </h5>
                                    <p class="elementor-icon-box-description">
                                      Boost online visibility with our SEM and
                                      SEO expertise
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </body>
      </div>
    </>
  );
};

export default About;
