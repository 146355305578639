import React from "react";
import DuotoneSvG from "./DuotoneSVG";
import "./Style.css";
function Nav() {
  return (
    <body class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
      <DuotoneSvG />

      <div
        data-elementor-type="wp-post"
        data-elementor-id="734"
        class="elementor elementor-734"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-7b20fcc elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
          data-id="7b20fcc"
          data-element_type="section"
          data-settings={{
            background_background: "classic",
            animation: "fadeIn",
            shape_divider_bottom: "opacity-fan",
          }}
        >
          <div className="elementor-background-overlay"></div>
          <div
            className="elementor-shape elementor-shape-bottom"
            data-negative="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 283.5 19.6"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                style={{ opacity: 0.33 }}
                d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"
              />
              <path
                className="elementor-shape-fill"
                style={{ opacity: 0.33 }}
                d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"
              />
              <path
                className="elementor-shape-fill"
                style={{ opacity: 0.33 }}
                d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"
              />
              <path
                className="elementor-shape-fill"
                d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"
              />
            </svg>
          </div>
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6aceea1"
              data-id="6aceea1"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-29e94c7 elementor-invisible elementor-widget elementor-widget-heading"
                  data-id="29e94c7"
                  data-element_type="widget"
                  data-settings={{ _animation: "fadeInUp" }}
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h1 className="elementor-heading-title elementor-size-default">
                      We design creative digital solutions
                    </h1>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-54846c2 elementor-invisible elementor-widget elementor-widget-text-editor"
                  data-id="54846c2"
                  data-element_type="widget"
                  data-settings={{ _animation: "fadeInUp" }}
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <style>
                      {`
                    /* Custom CSS styles */
                    .elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
                      background-color: #69727d;
                      color: #fff;
                    }

                    .elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
                      color: #69727d;
                      border: 3px solid;
                      background-color: transparent;
                    }

                    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
                      margin-top: 8px;
                    }

                    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
                      width: 1em;
                      height: 1em;
                    }

                    .elementor-widget-text-editor .elementor-drop-cap {
                      float: left;
                      text-align: center;
                      line-height: 1;
                      font-size: 50px;
                    }

                    .elementor-widget-text-editor .elementor-drop-cap-letter {
                      display: inline-block;
                    }
                  `}
                    </style>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo adipiscing elit.
                    </p>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-975815d elementor-align-center elementor-invisible elementor-widget elementor-widget-button"
                  data-id="975815d"
                  data-element_type="widget"
                  data-settings={{ _animation: "fadeInUp" }}
                  data-widget_type="button.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-button-wrapper">
                      <a
                        className="elementor-button elementor-button-link elementor-size-sm"
                        href="#"
                      >
                        <span className="elementor-button-content-wrapper">
                          <span className="elementor-button-icon elementor-align-icon-right">
                            <i
                              aria-hidden="true"
                              className="fas fa-arrow-right"
                            ></i>
                          </span>
                          <span className="elementor-button-text">
                            Contact Us
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        class="elementor-element elementor-element-163a419 elementor-invisible elementor-widget elementor-widget-progress"
        data-id="163a419"
        data-element_type="widget"
        data-settings='{"_animation":"fadeInUp"}'
        data-widget_type="progress.default"
      >
        <div class="elementor-widget-container">
          <span class="elementor-title">Mobile App</span>

          <div
            class="elementor-progress-wrapper"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuenow="80"
          >
            <div class="elementor-progress-bar" data-max="80">
              <span class="elementor-progress-text"></span>
              <span class="elementor-progress-percentage">80%</span>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Nav;
