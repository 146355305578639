import React from "react";
import "./Style.css";
import hope from "./image/ADZENON_Main Black Logo with Slogan.png";
import Footer from "./Footer";
import Ban from "./Ban";
import Navv from "./Navv";
const Contact = () => {
  return (
    <>
      <Navv />
      <div class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="445"
          class="elementor elementor-445"
        >
          <Ban />
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-d9835d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="d9835d5"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8b05f6d"
                data-id="8b05f6d"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-74da524 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="74da524"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-53780f5"
                        data-id="53780f5"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-f7367fa  elementor-widget elementor-widget-heading"
                            data-id="f7367fa"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="heading.default"
                          ></div>
                          <div
                            class="elementor-element elementor-element-658f629  elementor-widget elementor-widget-text-editor"
                            data-id="658f629"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="text-editor.default"
                          >
                            <div class="elementor-widget-container">
                              <form>
                                <fieldset>
                                  <div style={{ height: "800px" }}>
                                    <iframe
                                      src="https://calendly.com/will-xta/introductory-call"
                                      width="100%"
                                      height="100%"
                                      frameborder="0"
                                    ></iframe>
                                  </div>
                                </fieldset>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-b3d6da6"
                        data-id="b3d6da6"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-8effa8e  elementor-widget elementor-widget-heading"
                            data-id="8effa8e"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <h5
                                class="elementor-heading-title elementor-size-default"
                                style={{ textTransform: "capitalize" }}
                              >
                                Free 30-Minute Sustainable Scaling Session
                              </h5>{" "}
                              <br></br>
                              <h6 style={{ textTransform: "capitalize" }}>
                                Who is It For?
                              </h6>{" "}
                              <p>
                                Are you a brand looking to make a meaningful
                                impact whilst rapidly and exponentially
                                exploding the sales for your business? Our free
                                strategy session is tailored just for you.
                                Whether you&#39;re a budding eco-conscious
                                startup or an established green enterprise,
                                we&#39;re here to help you navigate the digital
                                landscape and amplify your message.
                              </p>
                            </div>
                          </div>

                          <div
                            class="elementor-element elementor-element-8effa8e  elementor-widget elementor-widget-heading"
                            data-id="8effa8e"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <h6
                                class="elementor-heading-title elementor-size-default"
                                style={{ textTransform: "capitalize" }}
                              >
                                Why Book a Free Strategy Session?
                              </h6>{" "}
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-daa2426 elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                            data-id="daa2426"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="icon-list.default"
                          >
                            <div class="elementor-widget-container">
                              <ul class="elementor-icon-list-items">
                                <li class="elementor-icon-list-item">
                                  <span class="elementor-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      class="fas fa-check"
                                      style={{ marginRight: "10px" }}
                                    ></i>
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    <span
                                      class="__cf_email__"
                                      data-cfemail="6c010d05002c07090902420f03"
                                    >
                                      <b>Customised Strategies: </b> We
                                      understand the unique challenges
                                      sustainable brands face. Our free strategy
                                      session provides personalised, data-driven
                                      strategies crafted to maximise your
                                      impact.
                                    </span>
                                  </span>
                                </li>
                                <li class="elementor-icon-list-item">
                                  <span class="elementor-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      class="fas fa-check"
                                      style={{ marginRight: "10px" }}
                                    ></i>
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    <b>Balancing Profit and Purpose: </b> We
                                    believe in a harmonious blend of
                                    profitability and purpose. Let us guide you
                                    in achieving sustainable growth that
                                    benefits both your brand and the
                                    environment.
                                  </span>
                                </li>
                                <li class="elementor-icon-list-item">
                                  <span class="elementor-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      class="fas fa-check"
                                      style={{ marginRight: "10px" }}
                                    ></i>
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    <b>Minimising Environmental Impact: </b> Our
                                    experts are dedicated to strategies that not
                                    only boost sales but also minimise your
                                    brand&#39;s footprint. Together, we&#39;ll
                                    pave the way for a greener, more prosperous
                                    future.
                                  </span>
                                </li>
                              </ul>
                              <p>
                                Ready to take the next step? Book your free
                                strategy session now and join us in
                                revolutionising sustainable brands!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Contact;
