// // ParentTable.js
// import React, { useState } from "react";

// import { useNavigate } from "react-router-dom";
// import "./newpage.css";
// import vivid from "./vivid.jpg";
// import NavPage from "./NavPage";
// const NewPage = () => {
//   return (
//     <>
//       <body class="framer-body-augiA20Il">
//         <div
//           id="main"
//           data-framer-hydrate-v2='{"routeId":"augiA20Il","localizationId":"default"}'
//           data-framer-ssr-released-at="2023-06-29T12:18:49.096Z"
//           data-framer-page-optimized-at="2023-07-04T12:15:15.060Z"
//         >
//           <div
//             class=" framer-PLftF framer-tg9sX framer-7HMie framer-Azhty"
//             style={{ display: "contents" }}
//           >
//             <div
//               class="framer-72rtr7"
//               style={{ minHeight: " 100%", width: "auto" }}
//             >
//               <div
//                 class="framer-ise40l"
//                 data-framer-name="Hero"
//                 id="hero"
//                 name="Hero"
//               >
//                 <div
//                   style={{
//                     position: "absolute",
//                     borderRadius: "inherit",
//                     top: "0",
//                     right: "0",
//                     bottom: "0",
//                     left: "0",
//                   }}
//                   data-framer-background-image-wrapper="true"
//                 >
//                   <img
//                     src={vivid}
//                     alt=""
//                     style={{
//                       display: "block",
//                       width: "100%",
//                       height: "100%",
//                       borderRadius: "inherit",
//                       objectPosition: "center",
//                       objectFit: "cover",
//                       imageRendering: "auto",
//                     }}
//                   />
//                 </div>
//                 <div class="ssr-variant hidden-1osa651 hidden-11urbq2">
//                   <div
//                     class="framer-yri3j9-container"
//                     data-framer-appear-id="yri3j9"
//                     style={{
//                       opacity: " 0.001",
//                       // transform: perspective(1200px) translateX(0px)
//                       //   translateY(-50px) scale(1) rotate(0deg) rotateX(0deg)
//                       //   rotateY(0deg) translateZ(0)
//                     }}
//                   >
//                     <div
//                       class="framer-o5CO1 framer-3gx1I framer-v-1fr5juk"
//                       style={{ display: "contents" }}
//                       tabindex="0"
//                     >
//                       <NavPage />
//                     </div>
//                   </div>
//                 </div>
//                 <NavPage />
//               </div>
//             </div>
//           </div>
//           <div class="ssr-variant hidden-1osa651 hidden-72rtr7">
//             <div
//               class="framer-yri3j9-container"
//               data-framer-appear-id="6my6pb"
//               style={myStyle}
//             >
//               <div
//                 class="framer-o5CO1 framer-3gx1I framer-v-1j04lfy"
//                 style={{ display: "contents" }}
//                 tabindex="0"
//               >
//                 <nav
//                   class="framer-1fr5juk"
//                   data-framer-name="Phone"
//                   style={{ backgroundColor: "rgba(0, 0, 0, 0)", width: "100%" }}
//                 >
//                   <div class="framer-kie9hf" data-framer-name="Title and Icon">
//                     <a
//                       data-framer-component-type="SVG"
//                       data-framer-name="graphic"
//                       href="index.html"
//                       data-framer-page-link-current="true"
//                       style={{
//                         imageRendering: "pixelated",
//                         flexShrink: "0",
//                         fill: "black",
//                         color: "black",
//                       }}
//                       class="framer-qt5d49 framer-17pyjxn"
//                     >
//                       <div class="svgContainer" style={myStyle2}>
//                         <svg>
//                           <use href="#svg-653693363_2030"></use>
//                         </svg>
//                       </div>
//                     </a>
//                     <div
//                       class="framer-1yzo1q7"
//                       data-framer-name="Icon"
//                       data-highlight="true"
//                       tabindex="0"
//                     >
//                       <div
//                         class="framer-1stmc0l"
//                         data-framer-name="Bottom"
//                         style={myStyle3}
//                       ></div>
//                       <div
//                         class="framer-1lqapr6"
//                         data-framer-name="Mid"
//                         style={myStyle4}
//                       ></div>
//                       <div
//                         class="framer-dxqqsp"
//                         data-framer-name="Top"
//                         style={myStyle5}
//                       ></div>
//                     </div>
//                   </div>
//                 </nav>
//               </div>
//             </div>
//           </div>
//           <div
//             class="framer-1o8plad"
//             data-framer-name="Frame 43"
//             name="Frame 43"
//           >
//             <div class="ssr-variant hidden-1osa651 hidden-11urbq2">
//               <div
//                 class="framer-sky8lo"
//                 data-framer-appear-id="sky8lo"
//                 data-framer-name="Open your first crypto wallet, right now!"
//                 style={myStyle6}
//                 data-framer-component-type="RichTextContainer"
//               >
//                 <h1
//                   class="framer-text framer-styles-preset-3nqyhf"
//                   data-styles-preset="YAP816Y5n"
//                   style={myStyle7}
//                 >
//                   Open your first crypto wallet, right now!
//                 </h1>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div id="__framer-badge-container"></div>
//       </body>
//     </>
//   );
// };

// export default NewPage;

import React from "react";

const NewPage = () => {
  return (window.location.href = "https://admin.adzenon.com");
};

export default NewPage;
