import React from "react";
import "./Style.css";
import Footer from "./Footer";
const Single = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="529"
        class="elementor elementor-529"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-ec83dd8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="ec83dd8"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-201cfd5"
              data-id="201cfd5"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-bc9baee elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="bc9baee"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0c34312"
                      data-id="0c34312"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-9e6a205  elementor-widget elementor-widget-heading"
                          data-id="9e6a205"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Hexa Magazine
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-b878ca5  elementor-widget elementor-widget-text-editor"
                          data-id="b878ca5"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Ut elit tellus, luctus nec ullamcorper
                              mattis, pulvinar dapibus leo magna aliqua.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-34839e2"
                      data-id="34839e2"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-c343d49  elementor-widget elementor-widget-heading"
                          data-id="c343d49"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h6 class="elementor-heading-title elementor-size-default">
                              Goals
                            </h6>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-928270c  elementor-widget elementor-widget-text-editor"
                          data-id="928270c"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Ut elit tellus, luctus nec.
                            </p>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-22ed842 elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                          data-id="22ed842"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="icon-list.default"
                        >
                          <div class="elementor-widget-container">
                            <ul class="elementor-icon-list-items">
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    class="fas fa-check"
                                  ></i>{" "}
                                </span>
                                <span class="elementor-icon-list-text">
                                  Digital Marketing
                                </span>
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    class="fas fa-check"
                                  ></i>{" "}
                                </span>
                                <span class="elementor-icon-list-text">
                                  Social Branding
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5d85355"
                      data-id="5d85355"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-d99ad79  elementor-widget elementor-widget-heading"
                          data-id="d99ad79"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h6 class="elementor-heading-title elementor-size-default">
                              Client
                            </h6>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-9771802 elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                          data-id="9771802"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="icon-list.default"
                        >
                          <div class="elementor-widget-container">
                            <ul class="elementor-icon-list-items">
                              <li class="elementor-icon-list-item">
                                <a href="#">
                                  <span class="elementor-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      class="icon icon-link"
                                    ></i>{" "}
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    hexamagz.io
                                  </span>
                                </a>
                              </li>
                              <li class="elementor-icon-list-item">
                                <a href="#">
                                  <span class="elementor-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      class="fab fa-instagram"
                                    ></i>{" "}
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    @hexa.magz
                                  </span>
                                </a>
                              </li>
                              <li class="elementor-icon-list-item">
                                <a href="#">
                                  <span class="elementor-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      class="fab fa-youtube"
                                    ></i>{" "}
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    Hexa Magazine
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-ec72bb4  elementor-widget elementor-widget-heading"
                          data-id="ec72bb4"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h6 class="elementor-heading-title elementor-size-default">
                              Date
                            </h6>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-37734c9  elementor-widget elementor-widget-text-editor"
                          data-id="37734c9"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>January 2023</p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-70809ef elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
          data-id="70809ef"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9a27dcd"
              data-id="9a27dcd"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7747b6a elementor-widget__width-initial  elementor-widget elementor-widget-heading"
                  data-id="7747b6a"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      1
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-3b74693 elementor-position-left elementor-widget__width-initial elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                  data-id="3b74693"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="image-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-image-box-wrapper">
                      <div class="elementor-image-box-content">
                        <h5 class="elementor-image-box-title">Research</h5>
                        <p class="elementor-image-box-description">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-ed0a3e6 elementor-widget__width-initial  elementor-widget elementor-widget-heading"
                  data-id="ed0a3e6"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      2
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-f759e8f elementor-position-left elementor-widget__width-initial elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                  data-id="f759e8f"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="image-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-image-box-wrapper">
                      <div class="elementor-image-box-content">
                        <h5 class="elementor-image-box-title">Development</h5>
                        <p class="elementor-image-box-description">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-3444969 elementor-widget__width-initial  elementor-widget elementor-widget-heading"
                  data-id="3444969"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      3
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-fef0e31 elementor-position-left elementor-widget__width-initial elementor-vertical-align-top  elementor-widget elementor-widget-image-box"
                  data-id="fef0e31"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="image-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-image-box-wrapper">
                      <div class="elementor-image-box-content">
                        <h5 class="elementor-image-box-title">Strategy</h5>
                        <p class="elementor-image-box-description">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-36b762b"
              data-id="36b762b"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-3b53b03 elementor-arrows-position-inside  elementor-widget elementor-widget-image-carousel"
                  data-id="3b53b03"
                  data-element_type="widget"
                  data-settings='{"slides_to_show":"1","slides_to_show_tablet":"1","navigation":"arrows","pause_on_hover":"no","pause_on_interaction":"no","_animation":"fadeInUp","autoplay":"yes","autoplay_speed":5000,"infinite":"yes","effect":"slide","speed":500}'
                  data-widget_type="image-carousel.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      class="elementor-image-carousel-wrapper swiper-container"
                      dir="ltr"
                    >
                      <div
                        class="elementor-image-carousel swiper-wrapper"
                        aria-live="off"
                      >
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="1 of 2"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/26-1.jpg"
                              alt="26"
                            />
                          </figure>
                        </div>
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="2 of 2"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/20.jpg"
                              alt="20.jpg"
                            />
                          </figure>
                        </div>{" "}
                      </div>
                      <div
                        class="elementor-swiper-button elementor-swiper-button-prev"
                        role="button"
                        tabindex="0"
                      >
                        <i aria-hidden="true" class="fas fa-arrow-left"></i>{" "}
                      </div>
                      <div
                        class="elementor-swiper-button elementor-swiper-button-next"
                        role="button"
                        tabindex="0"
                      >
                        <i aria-hidden="true" class="fas fa-arrow-right"></i>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Single;
