import "./Single.css";

import { useContext, useState } from "react";

import BlogBan from "./BlogBan";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "./hooks/useFetch";

const SingleBlog = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data, loading, error } = useFetch(`/properties/find/${id}`);

  const navigate = useNavigate();

  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="568"
        class="elementor elementor-568"
      >
        <BlogBan />
      </div>
    </div>
  );
};

export default SingleBlog;
