import React from "react";
import Footer from "./Footer";
import Okay from "./Okay";

import Swipe from "./Swipe";
import service1 from "./image/se1.JPG";
import service2 from "./image/se2.JPG";
import Navv from "./Navv";
import {
  FaArrowRight,
  FaCheck,
  FaLaptop,
  FaUserEdit,
  FaSearch,
  FaMobile,
  FaBullhorn,
  FaShareAlt,
} from "react-icons/fa";
const Service = () => {
  return (
    <>
      <Navv />
      <div class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="401"
          class="elementor elementor-401"
        >
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-5574866 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
            data-id="5574866"
            data-element_type="section"
            data-settings='{"background_background":"classic","animation":"fadeIn"}'
          >
            <div class="elementor-background-overlay"></div>
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-689ba33"
                data-id="689ba33"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-adb6532  elementor-widget elementor-widget-heading"
                    data-id="adb6532"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h1 class="elementor-heading-title elementor-size-default">
                        Our Services
                      </h1>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-bd58def  elementor-widget elementor-widget-text-editor"
                    data-id="bd58def"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <p></p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-04b891b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="04b891b"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1e2cf99"
                data-id="1e2cf99"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-0f500c2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="0f500c2"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1229980"
                        data-id="1229980"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-5c83808  elementor-widget elementor-widget-heading"
                            data-id="5c83808"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <h2 class="elementor-heading-title elementor-size-default">
                                What we do
                              </h2>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c457c11"
                        data-id="c457c11"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-ae9ba26  elementor-widget elementor-widget-text-editor"
                            data-id="ae9ba26"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="text-editor.default"
                          >
                            <div class="elementor-widget-container">
                              <p>
                                Our range of services are driven by data and a
                                deep authentic human connection. We skyrocket
                                your sales all while minimsing your impact on
                                the environment
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-1e7b938 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="1e7b938"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e825590"
                        data-id="e825590"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-280b1fb elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                            data-id="280b1fb"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-grow">
                                    <FaLaptop />
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h5 class="elementor-icon-box-title">
                                    <span>Sustainable Website Development</span>
                                  </h5>
                                  <p class="elementor-icon-box-description">
                                    We create stunning digital experiences that
                                    are also energy-efficient and low-impact on
                                    the environment.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-d55ba41 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                            data-id="d55ba41"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-grow">
                                    <FaUserEdit />
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h5 class="elementor-icon-box-title">
                                    <span>User-generated Content </span>
                                  </h5>
                                  <p class="elementor-icon-box-description">
                                    Creating engaging User-generated Content
                                    (UGC) that converts at scale.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-9b937c6 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                            data-id="9b937c6"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="icon-box.default"
                          ></div>
                        </div>
                      </div>
                      <div
                        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1e96c3b"
                        data-id="1e96c3b"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-223b862 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                            data-id="223b862"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-grow">
                                    <FaSearch />
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h5 class="elementor-icon-box-title">
                                    <span>Search Engine Optimization </span>
                                  </h5>
                                  <p class="elementor-icon-box-description">
                                    Claim your throne at the top of search
                                    results, ensuring your brand claims the
                                    spotlight it deserves.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-fca9974 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                            data-id="fca9974"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-grow">
                                    <FaBullhorn />
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h5 class="elementor-icon-box-title">
                                    <span>Search Engine Marketing (PPC) </span>
                                  </h5>
                                  <p class="elementor-icon-box-description">
                                    Data-driven strategies that turn clicks into
                                    customers and ROI into your growth story.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1e96c3b"
                        data-id="1e96c3b"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-223b862 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                            data-id="223b862"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-grow">
                                    <FaMobile />
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h5 class="elementor-icon-box-title">
                                    <span>Sustainable App Development </span>
                                  </h5>
                                  <p class="elementor-icon-box-description">
                                    We craft sustainable apps, minimizing carbon
                                    footprint with energy-efficient coding and
                                    eco-friendly design, all hosted on
                                    sustainable infrastructure
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-fca9974 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                            data-id="fca9974"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                            data-widget_type="icon-box.default"
                          >
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <span class="elementor-icon elementor-animation-grow">
                                    <FaShareAlt />
                                  </span>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h5 class="elementor-icon-box-title">
                                    <span>Paid social </span>
                                  </h5>
                                  <p class="elementor-icon-box-description">
                                    Maximum react, engagement , and conversions
                                    with our expertly crafted campaigns{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-5053ed7 elementor-section-boxed elementor-section-height-default elementor-section-height-default "
            data-id="5053ed7"
            data-element_type="section"
            data-settings='{"background_background":"classic","animation":"fadeIn"}'
          >
            <div class="elementor-background-overlay"></div>
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-337b230"
                data-id="337b230"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-8ceba7d  elementor-widget elementor-widget-image"
                    data-id="8ceba7d"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInLeft"}'
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <img
                        decoding="async"
                        width="750"
                        height="600"
                        src={service2}
                        class="attachment-full size-full wp-image-167"
                        alt=""
                        loading="lazy"
                        sizes="(max-width: 750px) 100vw, 750px"
                      />{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-767c259  elementor-widget elementor-widget-image"
                    data-id="767c259"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <img
                        decoding="async"
                        width="800"
                        height="800"
                        src={service1}
                        class="attachment-full size-full wp-image-90"
                        alt=""
                        loading="lazy"
                        sizes="(max-width: 800px) 100vw, 800px"
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-242adbb"
                data-id="242adbb"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-0a52aff  elementor-widget elementor-widget-heading"
                    data-id="0a52aff"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">
                        OUR UNIQUE SYSTEM THAT GUARANTEES HIGH ROI
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-e903975  elementor-widget elementor-widget-text-editor"
                    data-id="e903975"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <p>
                        Our CAF system turbocharges your sales funnel, producing
                        measurable results from every marketing pound spent
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-f803f23  elementor-widget elementor-widget-elementskit-simple-tab"
                    data-id="f803f23"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="elementskit-simple-tab.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="ekit-wid-con">
                        {" "}
                        <div class="elementkit-tab-wraper   elementskit-fitcontent-tab ">
                          <ul class="nav nav-tabs elementkit-tab-nav ">
                            <li class="elementkit-nav-item elementor-repeater-item-9f213b4">
                              <a
                                class="elementkit-nav-link  active show left-pos"
                                id="content-9f213b464f7a0f48266e-tab"
                                data-ekit-handler-id="-monitor"
                                data-ekit-toggle="tab"
                                data-target="#content-9f213b464f7a0f48266e"
                                href="#Content-9f213b464f7a0f48266e"
                                data-ekit-toggle-trigger="click"
                                aria-describedby="Content-9f213b464f7a0f48266e"
                              >
                                <span
                                  class="elementskit-tab-title"
                                  style={{
                                    listStyle: "none",
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  Creatives <br></br>that convert
                                </span>
                              </a>
                            </li>
                            <li class="elementkit-nav-item elementor-repeater-item-d0aee21">
                              <a
                                class="elementkit-nav-link  left-pos"
                                id="content-d0aee2164f7a0f48266e-tab"
                                data-ekit-handler-id="-analyze"
                                data-ekit-toggle="tab"
                                data-target="#content-d0aee2164f7a0f48266e"
                                href="#Content-d0aee2164f7a0f48266e"
                                data-ekit-toggle-trigger="click"
                                aria-describedby="Content-d0aee2164f7a0f48266e"
                              >
                                <span
                                  class="elementskit-tab-title"
                                  style={{
                                    listStyle: "none",
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  Audience <br></br>that acts
                                </span>
                              </a>
                            </li>
                            <li class="elementkit-nav-item elementor-repeater-item-e4fef1b">
                              <a
                                class="elementkit-nav-link  left-pos"
                                id="content-e4fef1b64f7a0f48266e-tab"
                                data-ekit-handler-id="-multiply"
                                data-ekit-toggle="tab"
                                data-target="#content-e4fef1b64f7a0f48266e"
                                href="#Content-e4fef1b64f7a0f48266e"
                                data-ekit-toggle-trigger="click"
                                aria-describedby="Content-e4fef1b64f7a0f48266e"
                              >
                                <span
                                  class="elementskit-tab-title"
                                  style={{
                                    listStyle: "none",
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  Full funnel <br></br>approach
                                </span>
                              </a>
                            </li>
                          </ul>

                          <div class="tab-content elementkit-tab-content">
                            <div
                              class="tab-pane elementkit-tab-pane elementor-repeater-item-9f213b4  active show"
                              id="content-9f213b464f7a0f48266e"
                              role="tabpanel"
                              aria-labelledby="content-9f213b464f7a0f48266e-tab"
                            >
                              <div class="animated fadeIn">
                                <p>
                                  Tap into the Power of Highly Authentic
                                  User-Generated Content to Build Trust,
                                  Increase Engagement, and Drive Customer
                                  Loyalty
                                </p>{" "}
                              </div>
                            </div>
                            <div
                              class="tab-pane elementkit-tab-pane elementor-repeater-item-d0aee21 "
                              id="content-d0aee2164f7a0f48266e"
                              role="tabpanel"
                              aria-labelledby="content-d0aee2164f7a0f48266e-tab"
                            >
                              <div class="animated fadeIn">
                                Understand Your Target Audience Fully. Our Paid
                                Social Specialists Drive Action with Data-Driven
                                Strategies
                              </div>
                            </div>
                            <div
                              class="tab-pane elementkit-tab-pane elementor-repeater-item-e4fef1b "
                              id="content-e4fef1b64f7a0f48266e"
                              role="tabpanel"
                              aria-labelledby="content-e4fef1b64f7a0f48266e-tab"
                            >
                              <div class="animated fadeIn">
                                <p>
                                  SEM and website optimisation that attract,
                                  engage, and convert potential customers at
                                  every stage of the sales funnel
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-cf3425b elementor-align-left  elementor-widget elementor-widget-button"
                    data-id="cf3425b"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="button.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a
                          class="elementor-button elementor-button-link elementor-size-sm"
                          href="/contact"
                        >
                          <span class="elementor-button-content-wrapper">
                            <span class="elementor-button-icon elementor-align-icon-right">
                              <FaArrowRight style={{ color: "black" }} />
                            </span>
                            <span
                              class="elementor-button-text"
                              style={{ color: "black" }}
                            >
                              {" "}
                              Get Started
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-78b2688 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="78b2688"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-25ffb51"
                data-id="25ffb51"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-839199b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="839199b"
                    data-element_type="section"
                  >
                    <div class="elementor-container elementor-column-gap-default">
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c586b2c"
                        data-id="c586b2c"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-a26869c  elementor-widget elementor-widget-heading"
                            data-id="a26869c"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="heading.default"
                          >
                            <div class="elementor-widget-container">
                              <h5 class="elementor-heading-title elementor-size-default">
                                Our highest performing creatives
                              </h5>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f7593d2"
                        data-id="f7593d2"
                        data-element_type="column"
                      >
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                            class="elementor-element elementor-element-02b5fea  elementor-widget elementor-widget-text-editor"
                            data-id="02b5fea"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="text-editor.default"
                          >
                            <div class="elementor-widget-container">
                              <p>
                                A showcase of some of our highest performing
                                user-generated content (UGC) made in
                                collaboration with our creative partner.
                              </p>{" "}
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-38954d9 elementor-align-left  elementor-widget elementor-widget-button"
                            data-id="38954d9"
                            data-element_type="widget"
                            data-settings='{"_animation":"fadeInUp"}'
                            data-widget_type="button.default"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <Swipe />
                </div>
              </div>
            </div>
          </section>
          <Okay />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Service;
