import React from "react";
import Footer from "./Footer";
import Okay from "./Okay";
import "./Blo.css"; // Import your custom CSS file here
import Ban2 from "./Ban2";

import { Link } from "react-router-dom";
import useFetch from "./hooks/useFetch";
import Navv from "./Navv";
const Blog = () => {
  const { data, loading, error } = useFetch("/properties?featured=true");
  return (
    <>
      <Navv />
      <div class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="568"
          className="elementor elementor-568"
        >
          <Ban2 />
          <div>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e1b87d4 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
              data-id="e1b87d4"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"fadeIn"}'
            >
              <div className="elementor-background-overlay"></div>
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d5ea811"
                  data-id="d5ea811"
                  data-element_type="column"
                ></div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-39056ac elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="39056ac"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9a97abb"
                  data-id="9a97abb"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-22f3305 elementor-widget elementor-widget-elementskit-blog-posts"
                      data-id="22f3305"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="elementskit-blog-posts.default"
                    >
                      <div>
                        <div>
                          <div id="post-items--22f3305">
                            <div className="row">
                              {data &&
                                data.map((item) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-sm-12 mb-4 custom-card" // Adjust column classes
                                    key={item?._id}
                                  >
                                    <div className="elementskit-entry-thumb">
                                      <Link to={`/singleblog/${item?._id}`}>
                                        <img
                                          decoding="async"
                                          src={item?.photos}
                                          alt="Digital marketing for small biz"
                                          className="card-img-top custom-card-img"
                                          style={{
                                            height: "300px !important",
                                            width: "100% !important",
                                            margin: "0", // Remove margin
                                            padding: "0", // Remove padding
                                            boxSizing: "border-box", // Ensure box-sizing
                                          }}
                                        />
                                      </Link>
                                      <div className="card-body">
                                        <h5 className="card-title">
                                          <Link to={`/singleblog/${item?._id}`}>
                                            {item?.title}
                                          </Link>
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Blog;
