import React from "react";
import "./Style.css";
import one from "./image/5.JPG";
import two from "./image/8.JPG";
import { FaArrowRight } from "react-icons/fa";

const DuotoneSVG = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="401"
        class="elementor elementor-401"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-5053ed7 elementor-section-boxed elementor-section-height-default elementor-section-height-default "
          data-id="5053ed7"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-337b230"
              data-id="337b230"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-8ceba7d  elementor-widget elementor-widget-image"
                  data-id="8ceba7d"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInLeft"}'
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      decoding="async"
                      width="750"
                      height="600"
                      src={one}
                      class="attachment-full size-full wp-image-167"
                      alt=""
                      loading="lazy"
                      srcset={one}
                      sizes="(max-width: 750px) 100vw, 750px"
                    />{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-767c259  elementor-widget elementor-widget-image"
                  data-id="767c259"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      decoding="async"
                      width="800"
                      height="800"
                      src={two}
                      class="attachment-full size-full wp-image-90"
                      alt=""
                      loading="lazy"
                      srcset={two}
                      sizes="(max-width: 800px) 100vw, 800px"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-242adbb"
              data-id="242adbb"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-0a52aff  elementor-widget elementor-widget-heading"
                  data-id="0a52aff"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      OUR UNIQUE SYSTEM THAT GUARANTEES HIGH ROI
                    </h4>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-e903975  elementor-widget elementor-widget-text-editor"
                  data-id="e903975"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Our CAF system turbocharges your sales funnel, producing
                      measurable results from every marketing pound spent
                    </p>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-f803f23  elementor-widget elementor-widget-elementskit-simple-tab"
                  data-id="f803f23"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="elementskit-simple-tab.default"
                >
                  <div class="elementor-widget-container">
                    <div class="ekit-wid-con">
                      {" "}
                      <div class="elementkit-tab-wraper   elementskit-fitcontent-tab ">
                        <ul class="nav nav-tabs elementkit-tab-nav ">
                          <li class="elementkit-nav-item elementor-repeater-item-9f213b4">
                            <a
                              class="elementkit-nav-link  active show left-pos"
                              id="content-9f213b464f7a0f48266e-tab"
                              data-ekit-handler-id="-monitor"
                              data-ekit-toggle="tab"
                              data-target="#content-9f213b464f7a0f48266e"
                              href="#Content-9f213b464f7a0f48266e"
                              data-ekit-toggle-trigger="click"
                              aria-describedby="Content-9f213b464f7a0f48266e"
                            >
                              <span
                                class="elementskit-tab-title"
                                style={{ listStyle: "none", fontSize: "10px" }}
                              >
                                {" "}
                                Creative<br></br> that<br></br> Converts
                              </span>
                            </a>
                          </li>
                          <li class="elementkit-nav-item elementor-repeater-item-d0aee21">
                            <a
                              class="elementkit-nav-link  left-pos"
                              id="content-d0aee2164f7a0f48266e-tab"
                              data-ekit-handler-id="-analyze"
                              data-ekit-toggle="tab"
                              data-target="#content-d0aee2164f7a0f48266e"
                              href="#Content-d0aee2164f7a0f48266e"
                              data-ekit-toggle-trigger="click"
                              aria-describedby="Content-d0aee2164f7a0f48266e"
                            >
                              <span
                                class="elementskit-tab-title"
                                style={{ listStyle: "none", fontSize: "10px" }}
                              >
                                {" "}
                                Audiences <br></br>that Act
                              </span>
                            </a>
                          </li>
                          <li class="elementkit-nav-item elementor-repeater-item-e4fef1b">
                            <a
                              class="elementkit-nav-link  left-pos"
                              id="content-e4fef1b64f7a0f48266e-tab"
                              data-ekit-handler-id="-multiply"
                              data-ekit-toggle="tab"
                              data-target="#content-e4fef1b64f7a0f48266e"
                              href="#Content-e4fef1b64f7a0f48266e"
                              data-ekit-toggle-trigger="click"
                              aria-describedby="Content-e4fef1b64f7a0f48266e"
                            >
                              <span
                                class="elementskit-tab-title"
                                style={{ listStyle: "none", fontSize: "10px" }}
                              >
                                {" "}
                                Full-Funnel<br></br> Approach
                              </span>
                            </a>
                          </li>
                        </ul>

                        <div class="tab-content elementkit-tab-content">
                          <div
                            class="tab-pane elementkit-tab-pane elementor-repeater-item-9f213b4  active show"
                            id="content-9f213b464f7a0f48266e"
                            role="tabpanel"
                            aria-labelledby="content-9f213b464f7a0f48266e-tab"
                          >
                            <div class="animated fadeIn">
                              <p>
                                Tap into the Power of Highly Authentic
                                User-Generated Content to Build Trust, Increase
                                Engagement, and Drive Customer Loyalty
                              </p>{" "}
                            </div>
                          </div>
                          <div
                            class="tab-pane elementkit-tab-pane elementor-repeater-item-d0aee21 "
                            id="content-d0aee2164f7a0f48266e"
                            role="tabpanel"
                            aria-labelledby="content-d0aee2164f7a0f48266e-tab"
                          >
                            <div class="animated fadeIn">
                              Understand Your Target Audience Fully. Our Paid
                              Social Specialists Drive Action with Data-Driven
                              Strategies
                            </div>
                          </div>
                          <div
                            class="tab-pane elementkit-tab-pane elementor-repeater-item-e4fef1b "
                            id="content-e4fef1b64f7a0f48266e"
                            role="tabpanel"
                            aria-labelledby="content-e4fef1b64f7a0f48266e-tab"
                          >
                            <div class="animated fadeIn">
                              <p>
                                SEM and website optimisation that attract,
                                engage, and convert potential customers at every
                                stage of the sales funnel
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-cf3425b elementor-align-left  elementor-widget elementor-widget-button"
                  data-id="cf3425b"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <a
                        class="elementor-button elementor-button-link elementor-size-sm"
                        href="/contact"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-icon elementor-align-icon-right">
                            <FaArrowRight style={{ color: "black" }} />
                          </span>
                          <span
                            class="elementor-button-text"
                            style={{ color: "black" }}
                          >
                            Get Started
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DuotoneSVG;
