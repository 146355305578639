// ParentTable.js
import React from "react";
import DuotoneSVG from "./DuotoneSVG";
import New from "./New";
import "./Style.css";
import Footer from "./Footer";
import Banner from "./Banner";
import Rev from "./Rev";

const Home3 = () => {
  return (
    <body class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
      <div
        data-elementor-type="wp-post"
        data-elementor-id="734"
        class="elementor elementor-734"
      >
        >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-8acb873 elementor-section-boxed elementor-section-height-default elementor-section-height-default "
          data-id="8acb873"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cf861f6"
              data-id="cf861f6"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-ba6b57f elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                  data-id="ba6b57f"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="icon-list.default"
                >
                  <div class="elementor-widget-container">
                    <ul class="elementor-icon-list-items elementor-inline-items">
                      <li
                        class="elementor-icon-list-item elementor-inline-item"
                        style={{ listStyle: "none" }}
                      >
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>{" "}
                        </span>
                        <span class="elementor-icon-list-text">
                          Creative people
                        </span>
                      </li>
                      <li
                        class="elementor-icon-list-item elementor-inline-item"
                        style={{ listStyle: "none" }}
                      >
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>{" "}
                        </span>
                        <span class="elementor-icon-list-text">
                          good reviews
                        </span>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>{" "}
                        </span>
                        <span class="elementor-icon-list-text">
                          awesome design
                        </span>
                      </li>
                      <li
                        class="elementor-icon-list-item elementor-inline-item"
                        style={{ listStyle: "none" }}
                      >
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>{" "}
                        </span>
                        <span class="elementor-icon-list-text">
                          fast delivery
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};
export default Home3;
