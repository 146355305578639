import React from "react";

const Pricing = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="483"
        class="elementor elementor-483"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-954d569 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
          data-id="954d569"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0213894"
              data-id="0213894"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-9c04261  elementor-widget elementor-widget-heading"
                  data-id="9c04261"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Pricing
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-c4a946b elementor-widget elementor-widget-text-editor"
                  data-id="c4a946b"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-944604d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="944604d"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d7c21ec"
              data-id="d7c21ec"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-167f104  elementor-widget elementor-widget-elementskit-pricing"
                  data-id="167f104"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="elementskit-pricing.default"
                >
                  <div class="elementor-widget-container">
                    <div class="ekit-wid-con">
                      <div class="elementskit-single-pricing ">
                        <div class="elementskit-pricing-header ">
                          <h3 class=" elementskit-pricing-title">Basic </h3>
                          <p class=" elementskit-pricing-subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </p>
                        </div>
                        <div class=" elementskit-pricing-price-wraper has-tag ">
                          <div class="elementskit-pricing-tag"></div>
                          <span class="elementskit-pricing-price">
                            <sup class="currency">$</sup>
                            <span>5.9</span>

                            <sub class="period">Month</sub>
                          </span>
                        </div>
                        <div class="elementskit-pricing-content ">
                          <ul class="elementskit-pricing-lists">
                            <li class="elementor-repeater-item-74335e8">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Digital Marketing
                            </li>
                            <li class="elementor-repeater-item-ebfa371">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Content Strategy
                            </li>
                          </ul>
                        </div>
                        <div class="elementskit-pricing-btn-wraper ">
                          <a
                            href="#"
                            class="elementskit-pricing-btn  ekit-pricing-btn-icon-pos-right"
                          >
                            Choose Plan
                            <i
                              aria-hidden="true"
                              class="fas fa-arrow-right"
                            ></i>{" "}
                          </a>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f3d6a85"
              data-id="f3d6a85"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7472f34  elementor-widget elementor-widget-elementskit-pricing"
                  data-id="7472f34"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                  data-widget_type="elementskit-pricing.default"
                >
                  <div class="elementor-widget-container">
                    <div class="ekit-wid-con">
                      <div class="elementskit-single-pricing ">
                        <div class="elementskit-pricing-header ">
                          <h3 class=" elementskit-pricing-title">Standard </h3>
                          <p class=" elementskit-pricing-subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </p>
                        </div>
                        <div class=" elementskit-pricing-price-wraper has-tag ">
                          <div class="elementskit-pricing-tag"></div>
                          <span class="elementskit-pricing-price">
                            <sup class="currency">$</sup>
                            <span>17.9</span>

                            <sub class="period">Month</sub>
                          </span>
                        </div>
                        <div class="elementskit-pricing-content ">
                          <ul class="elementskit-pricing-lists">
                            <li class="elementor-repeater-item-74335e8">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Digital Marketing
                            </li>
                            <li class="elementor-repeater-item-ebfa371">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Content Strategy
                            </li>
                            <li class="elementor-repeater-item-9c1a660">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Branding Design
                            </li>
                          </ul>
                        </div>
                        <div class="elementskit-pricing-btn-wraper ">
                          <a
                            href="#"
                            class="elementskit-pricing-btn  ekit-pricing-btn-icon-pos-right"
                          >
                            Choose Plan
                            <i
                              aria-hidden="true"
                              class="fas fa-arrow-right"
                            ></i>{" "}
                          </a>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-9530bc8"
              data-id="9530bc8"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-8f09e3f  elementor-widget elementor-widget-elementskit-pricing"
                  data-id="8f09e3f"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp","_animation_delay":500}'
                  data-widget_type="elementskit-pricing.default"
                >
                  <div class="elementor-widget-container">
                    <div class="ekit-wid-con">
                      <div class="elementskit-single-pricing ">
                        <div class="elementskit-pricing-header ">
                          <h3 class=" elementskit-pricing-title">Premium </h3>
                          <p class=" elementskit-pricing-subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </p>
                        </div>
                        <div class=" elementskit-pricing-price-wraper has-tag ">
                          <div class="elementskit-pricing-tag"></div>
                          <span class="elementskit-pricing-price">
                            <sup class="currency">$</sup>
                            <span>29.9</span>

                            <sub class="period">Month</sub>
                          </span>
                        </div>
                        <div class="elementskit-pricing-content ">
                          <ul class="elementskit-pricing-lists">
                            <li class="elementor-repeater-item-74335e8">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Digital Marketing
                            </li>
                            <li class="elementor-repeater-item-ebfa371">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Content Strategy
                            </li>
                            <li class="elementor-repeater-item-9c1a660">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Branding Design
                            </li>
                            <li class="elementor-repeater-item-6de5f64">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Web Inspection
                            </li>
                            <li class="elementor-repeater-item-2a142b3">
                              <i aria-hidden="true" class="fas fa-check"></i>
                              Social Campaign
                            </li>
                          </ul>
                        </div>
                        <div class="elementskit-pricing-btn-wraper ">
                          <a
                            href="#"
                            class="elementskit-pricing-btn  ekit-pricing-btn-icon-pos-right"
                          >
                            Choose Plan
                            <i
                              aria-hidden="true"
                              class="fas fa-arrow-right"
                            ></i>{" "}
                          </a>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e41325f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e41325f"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8fb98c8"
              data-id="8fb98c8"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-93bccdf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="93bccdf"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-bb58034"
                      data-id="bb58034"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-9aca7f6  elementor-widget elementor-widget-heading"
                          data-id="9aca7f6"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              FAQs
                            </h2>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d5143be"
                      data-id="d5143be"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-eb7bc34  elementor-widget elementor-widget-text-editor"
                          data-id="eb7bc34"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Ut elit tellus, luctus nec ullamcorper
                              mattis, pulvinar dapibus leo.
                            </p>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-e817c3a elementor-align-left  elementor-widget elementor-widget-button"
                          data-id="e817c3a"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a
                                class="elementor-button elementor-button-link elementor-size-sm"
                                href="#"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-icon elementor-align-icon-right">
                                    <i
                                      aria-hidden="true"
                                      class="fas fa-arrow-right"
                                    ></i>{" "}
                                  </span>
                                  <span class="elementor-button-text">
                                    Contact Us
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-8f922d2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="8f922d2"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-dd34f75"
                      data-id="dd34f75"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-49f534f  elementor-widget elementor-widget-accordion"
                          data-id="49f534f"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="accordion.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-accordion">
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-7751"
                                  class="elementor-tab-title"
                                  data-tab="1"
                                  role="button"
                                  aria-controls="elementor-tab-content-7751"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Payment Methods
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-7751"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="1"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-7751"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-7752"
                                  class="elementor-tab-title"
                                  data-tab="2"
                                  role="button"
                                  aria-controls="elementor-tab-content-7752"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Billing System
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-7752"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="2"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-7752"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-7753"
                                  class="elementor-tab-title"
                                  data-tab="3"
                                  role="button"
                                  aria-controls="elementor-tab-content-7753"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Branding Design
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-7753"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="3"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-7753"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-7754"
                                  class="elementor-tab-title"
                                  data-tab="4"
                                  role="button"
                                  aria-controls="elementor-tab-content-7754"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Digital Downloads
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-7754"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="4"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-7754"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-7755"
                                  class="elementor-tab-title"
                                  data-tab="5"
                                  role="button"
                                  aria-controls="elementor-tab-content-7755"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Content Marketing
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-7755"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="5"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-7755"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ef80468"
                      data-id="ef80468"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-c16ba30  elementor-widget elementor-widget-accordion"
                          data-id="c16ba30"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInUp"}'
                          data-widget_type="accordion.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-accordion">
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-2021"
                                  class="elementor-tab-title"
                                  data-tab="1"
                                  role="button"
                                  aria-controls="elementor-tab-content-2021"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Warranty Service
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-2021"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="1"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-2021"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-2022"
                                  class="elementor-tab-title"
                                  data-tab="2"
                                  role="button"
                                  aria-controls="elementor-tab-content-2022"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Member area dashboard
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-2022"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="2"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-2022"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-2023"
                                  class="elementor-tab-title"
                                  data-tab="3"
                                  role="button"
                                  aria-controls="elementor-tab-content-2023"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    monthly report
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-2023"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="3"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-2023"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-2024"
                                  class="elementor-tab-title"
                                  data-tab="4"
                                  role="button"
                                  aria-controls="elementor-tab-content-2024"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Social Media Management
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-2024"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="4"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-2024"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                              <div class="elementor-accordion-item">
                                <div
                                  id="elementor-tab-title-2025"
                                  class="elementor-tab-title"
                                  data-tab="5"
                                  role="button"
                                  aria-controls="elementor-tab-content-2025"
                                  aria-expanded="false"
                                >
                                  <span
                                    class="elementor-accordion-icon elementor-accordion-icon-right"
                                    aria-hidden="true"
                                  >
                                    <span class="elementor-accordion-icon-closed">
                                      <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="elementor-accordion-icon-opened">
                                      <i class="icon icon-minus"></i>
                                    </span>
                                  </span>
                                  <a
                                    class="elementor-accordion-title"
                                    tabindex="0"
                                  >
                                    Sync Account Tools
                                  </a>
                                </div>
                                <div
                                  id="elementor-tab-content-2025"
                                  class="elementor-tab-content elementor-clearfix"
                                  data-tab="5"
                                  role="region"
                                  aria-labelledby="elementor-tab-title-2025"
                                >
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Ut elit tellus, luctus nec
                                  ullamcorper mattis, pulvinar dapibus leo.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pricing;
