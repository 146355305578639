import React from "react";
import Footer from "./Footer";
import "./font.css";

const Review = () => {
  return (
    <div
      data-elementor-type="wp-post"
      data-elementor-id="552"
      class="elementor elementor-552"
    >
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-4e7916a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="4e7916a"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8463894"
            data-id="8463894"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-842a369  elementor-widget elementor-widget-image"
                data-id="842a369"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInLeft"}'
                data-widget_type="image.default"
              >
                <div class="elementor-widget-container">
                  <img
                    decoding="async"
                    width="1000"
                    height="1000"
                    src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/26-1.jpg"
                    class="attachment-full size-full wp-image-243"
                    alt=""
                    loading="lazy"
                    srcset="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/26-1.jpg 1000w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/26-1-300x300.jpg 300w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/26-1-150x150.jpg 150w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/26-1-768x768.jpg 768w, https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/26-1-800x800.jpg 800w"
                    sizes="(max-width: 1000px) 100vw, 1000px"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5ef856b"
            data-id="5ef856b"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-743e755  elementor-widget elementor-widget-heading"
                data-id="743e755"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInRight"}'
                data-widget_type="heading.default"
              >
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">
                    Reviews
                  </h2>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-51edcef  elementor-widget elementor-widget-elementskit-testimonial"
                data-id="51edcef"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp"}'
                data-widget_type="elementskit-testimonial.default"
              >
                <div class="elementor-widget-container">
                  <div class="ekit-wid-con">
                    <div
                      class="elementskit-testimonial-slider ekit_testimonial_style_5 arrow_inside "
                      data-config='{"rtl":false,"arrows":true,"dots":false,"pauseOnHover":false,"autoplay":true,"speed":1000,"slidesPerGroup":2,"slidesPerView":1,"loop":true,"breakpoints":{"320":{"slidesPerView":1,"slidesPerGroup":1,"spaceBetween":10},"768":{"slidesPerView":2,"slidesPerGroup":1,"spaceBetween":10},"1024":{"slidesPerView":1,"slidesPerGroup":2,"spaceBetween":15}}}'
                    >
                      <div class="ekit-main-swiper swiper-container">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <div class="swiper-slide-inner">
                              <div class="elementskit-single-testimonial-slider elementskit-testimonial-slider-block-style elementskit-testimonial-slider-block-style-two   elementor-repeater-item-29f88f9">
                                <div class="elementskit-commentor-header">
                                  <ul class="elementskit-stars">
                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div class="elementskit-commentor-content">
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Ut elit tellus, luctus nec
                                    ullamcorper mattis, pulvinar dapibus leo.
                                  </p>
                                </div>

                                <div class="elementskit-commentor-bio">
                                  <div class="elementkit-commentor-details ">
                                    <div class="elementskit-commentor-image ekit-testimonial--avatar">
                                      <img
                                        decoding="async"
                                        width="100"
                                        height="100"
                                        src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/31.jpg"
                                        class="attachment-full size-full"
                                        alt=""
                                        loading="lazy"
                                      />{" "}
                                    </div>
                                    <div class="elementskit-profile-info">
                                      <strong class="elementskit-author-name">
                                        John Doe
                                      </strong>
                                      <span class="elementskit-author-des">
                                        Google
                                      </span>
                                    </div>
                                  </div>
                                  <div class="elementskit-icon-content elementskit-watermark-icon ">
                                    <i
                                      aria-hidden="true"
                                      class="icon icon-quote2"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="swiper-slide-inner">
                              <div class="elementskit-single-testimonial-slider elementskit-testimonial-slider-block-style elementskit-testimonial-slider-block-style-two   elementor-repeater-item-b8cd21a">
                                <div class="elementskit-commentor-header">
                                  <ul class="elementskit-stars">
                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>

                                    <li>
                                      <a>
                                        <i className="eicon-star active eicons-font"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div class="elementskit-commentor-content">
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Ut elit tellus, luctus nec
                                    ullamcorper mattis, pulvinar dapibus leo.
                                  </p>
                                </div>

                                <div class="elementskit-commentor-bio">
                                  <div class="elementkit-commentor-details ">
                                    <div class="elementskit-commentor-image ekit-testimonial--avatar">
                                      <img
                                        decoding="async"
                                        width="100"
                                        height="100"
                                        src="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/2022/10/32.jpg"
                                        class="attachment-full size-full"
                                        alt=""
                                        loading="lazy"
                                      />{" "}
                                    </div>
                                    <div class="elementskit-profile-info">
                                      <strong class="elementskit-author-name">
                                        Mike Lewis
                                      </strong>
                                      <span class="elementskit-author-des">
                                        Twitter
                                      </span>
                                    </div>
                                  </div>
                                  <div class="elementskit-icon-content elementskit-watermark-icon ">
                                    <i
                                      aria-hidden="true"
                                      class="icon icon-quote2"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="swiper-navigation-button swiper-button-prev">
                          <i class="fas fa-arrow-left"></i>
                        </div>
                        <div class="swiper-navigation-button swiper-button-next">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Review;
