import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./boot.css";
const contentBoxStyle = {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const cardStyle = {
  maxWidth: 800,
  minHeight: 400,
  margin: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 12,
};

const inputStyle = {
  width: "100%",
  marginBottom: "1rem",
};

const Ebooklogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const values = {
    email: "",
    password: "",
    remember: false,
  };

  const touched = {};
  const errors = {};

  const handleChange = (e) => {
    // Handle changes to form fields here
  };

  const handleBlur = (e) => {
    // Handle blur events on form fields here
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.email && values.password) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        handleOpenModal(); // Open the modal after successful login
      }, 1500);
    }
  };

  return (
    <div style={contentBoxStyle}>
      <div className="card" style={cardStyle}>
        <form onSubmit={handleSubmit} style={{ marginTop: "120px" }}>
          <h3
            style={{ fontWeight: "700", fontSize: "20px", textAlign: "center" }}
          >
            Your FREE E-book for Digital marketing
          </h3>
          <h6 style={{ fontSize: "15px", textAlign: "center" }}>
            Grab your free ebook now?
          </h6>
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            style={inputStyle}
          />
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            style={inputStyle}
          />

          <div>
            <NavLink to="/session/forgot-password" style={{ float: "right" }}>
              Forgot password?
            </NavLink>
          </div>
          <br />
          <br />
          <label
            style={{
              color: "white",
              textTransform: "none",
              fontSize: "15px",
              fontWeight: "100px",
            }}
          >
            <input
              type="checkbox"
              name="remember"
              checked={values.remember}
              onChange={handleChange}
              style={{ marginRight: "20px" }}
            />
            I agree to the privacy policy and to using my contact details to
            contact me for marketing purposes
          </label>

          <button
            type="submit"
            color="primary"
            loading={loading ? "true" : "false"}
            variant="contained"
            sx={{ my: 2 }}
            style={{ width: "100%", borderRadius: "50px" }}
            onClick={handleOpenModal}
          >
            Get my free E-book
          </button>
          <p>
            Do you want to go to the home page? <NavLink to="/">Home</NavLink>
          </p>
        </form>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p style={{ color: "black" }}>
              Thank you for downloading your free ebook. Your download has
              started.
            </p>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ebooklogin;
