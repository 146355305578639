import React, { useEffect } from "react";
import "./Style.css";

const New = () => {
  useEffect(() => {
    // Create link elements for stylesheets
    const linkElement1 = document.createElement("link");
    linkElement1.rel = "stylesheet";
    linkElement1.id = "elementor-post-895-css";
    linkElement1.href =
      "https://demo.sociolib.com/keen/wp-content/uploads/sites/22/elementor/css/post-895.css?ver=1679476252";
    linkElement1.media = "all";

    const linkElement2 = document.createElement("link");
    linkElement2.rel = "stylesheet";
    linkElement2.id = "e-animations-css";
    linkElement2.href =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=3.14.1";
    linkElement2.media = "all";

    // Create script elements
    const scriptElement1 = document.createElement("script");
    scriptElement1.dataset.cfasync = "false";
    scriptElement1.src =
      "https://demo.sociolib.com/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js";

    const scriptElement2 = document.createElement("script");
    scriptElement2.src =
      "https://demo.sociolib.com/keen/wp-content/themes/hello-elementor/assets/js/hello-frontend.min.js?ver=1.0.0";
    scriptElement2.id = "hello-theme-frontend-js";

    const scriptElement3 = document.createElement("script");
    scriptElement3.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementskit-lite/libs/framework/assets/js/frontend-script.js?ver=2.9.0";
    scriptElement3.id = "elementskit-framework-js-frontend-js";

    const scriptElement4 = document.createElement("script");
    scriptElement4.id = "elementskit-framework-js-frontend-js-after";
    scriptElement4.innerHTML = `
      var elementskit = {
        resturl: "https://demo.sociolib.com/keen/wp-json/elementskit/v1/",
      };
    `;

    const scriptElement5 = document.createElement("script");
    scriptElement5.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementskit-lite/widgets/init/assets/js/widget-scripts.js?ver=2.9.0";
    scriptElement5.id = "ekit-widget-scripts-js";

    const scriptElement6 = document.createElement("script");
    scriptElement6.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementor/assets/lib/jquery-numerator/jquery-numerator.min.js?ver=0.2.1";
    scriptElement6.id = "jquery-numerator-js";

    const scriptElement7 = document.createElement("script");
    scriptElement7.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementskit-lite/widgets/init/assets/js/animate-circle.min.js?ver=2.9.0";
    scriptElement7.id = "animate-circle-js";

    const scriptElement8 = document.createElement("script");
    scriptElement8.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js?ver=3.14.1";
    scriptElement8.id = "elementor-webpack-runtime-js";

    const scriptElement9 = document.createElement("script");
    scriptElement9.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementor/assets/js/frontend-modules.min.js?ver=3.14.1";
    scriptElement9.id = "elementor-frontend-modules-js";

    const scriptElement10 = document.createElement("script");
    scriptElement10.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementor/assets/lib/waypoints/waypoints.min.js?ver=4.0.2";
    scriptElement10.id = "elementor-waypoints-js";

    const scriptElement11 = document.createElement("script");
    scriptElement11.src =
      "https://demo.sociolib.com/keen/wp-includes/js/jquery/ui/core.min.js?ver=1.13.2";
    scriptElement11.id = "jquery-ui-core-js";

    const scriptElement12 = document.createElement("script");
    scriptElement12.id = "elementor-frontend-js-before";
    scriptElement12.innerHTML = `
      var elementorFrontendConfig = {
        environmentMode: {
          edit: false,
          wpPreview: false,
          isScriptDebug: false,
        },
        // ...rest of the configuration
      };
    `;

    const scriptElement13 = document.createElement("script");
    scriptElement13.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.14.1";
    scriptElement13.id = "elementor-frontend-js";

    const scriptElement14 = document.createElement("script");
    scriptElement14.id = "elementskit-elementor-js-extra";
    scriptElement14.innerHTML = `
      var ekit_config = {
        ajaxurl: "https:\/\/demo.sociolib.com\/keen\/wp-admin\/admin-ajax.php",
        nonce: "2fce8f4e76",
      };
    `;

    const scriptElement15 = document.createElement("script");
    scriptElement15.src =
      "https://demo.sociolib.com/keen/wp-content/plugins/elementskit-lite/widgets/init/assets/js/elementor.js?ver=2.9.0";
    scriptElement15.id = "elementskit-elementor-js";

    // Append link and script elements to the document
    document.head.appendChild(linkElement1);
    document.head.appendChild(linkElement2);
    document.head.appendChild(scriptElement1);
    document.head.appendChild(scriptElement2);
    document.head.appendChild(scriptElement3);
    document.head.appendChild(scriptElement4);
    document.head.appendChild(scriptElement5);
    document.head.appendChild(scriptElement6);
    document.head.appendChild(scriptElement7);
    document.head.appendChild(scriptElement8);
    document.head.appendChild(scriptElement9);
    document.head.appendChild(scriptElement10);
    document.head.appendChild(scriptElement11);
    document.head.appendChild(scriptElement12);
    document.head.appendChild(scriptElement13);
    document.head.appendChild(scriptElement14);
    document.head.appendChild(scriptElement15);

    // Clean up when the component unmounts
    return () => {
      document.head.removeChild(linkElement1);
      document.head.removeChild(linkElement2);
      document.head.removeChild(scriptElement1);
      document.head.removeChild(scriptElement2);
      document.head.removeChild(scriptElement3);
      document.head.removeChild(scriptElement4);
      document.head.removeChild(scriptElement5);
      document.head.removeChild(scriptElement6);
      document.head.removeChild(scriptElement7);
      document.head.removeChild(scriptElement8);
      document.head.removeChild(scriptElement9);
      document.head.removeChild(scriptElement10);
      document.head.removeChild(scriptElement11);
      document.head.removeChild(scriptElement12);
      document.head.removeChild(scriptElement13);
      document.head.removeChild(scriptElement14);
      document.head.removeChild(scriptElement15);
    };
  }, []);

  return (
    <div>
      <div
        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-09f4034"
        data-id="09f4034"
        data-element_type="column"
      >
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
            class="elementor-element elementor-element-d91c09f elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-invisible elementor-widget elementor-widget-icon-box"
            data-id="d91c09f"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp","_animation_delay":500}'
            data-widget_type="icon-box.default"
          >
            <div class="elementor-widget-container">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-grow">
                    <i aria-hidden="true" class="icon icon-layers"></i>{" "}
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h5 class="elementor-icon-box-title">
                    <span style={{ color: "white" }}>User Experience </span>
                  </h5>
                  <p class="elementor-icon-box-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
