// ParentTable.js
import React, { useState } from "react";
import DuotoneSVG from "./DuotoneSVG";
import New from "./New";
import "./Style.css";
import Footer from "./Footer";
import Banner from "./Banner";
import Rev from "./Rev";
import hope from "./image/re.png";
import one from "./image/4.JPG";
import two from "./image/10.JPG";
import three from "./image/3.JPG";
import VideoCaro from "./VideoCaro";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import trust from "./image/oneTree.jpeg";
import trust2 from "./image/bbc2.png";
import trust3 from "./image/hull2.png";

import trust4 from "./image/bon.png";
import Tag from "./Tag";
import Swipe from "./Swipe";
import Spon from "./Spon";
import Spon2 from "./Spon2";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import Nav from "./Nav";
import Navv from "./Navv";
import {
  FaArrowRight,
  FaCheck,
  FaLaptop,
  FaUserEdit,
  FaSearch,
} from "react-icons/fa";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      <Navv />
      <body class="envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
        {/* <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onDownload={handleDownload}
  />*/}
        <div
          data-elementor-type="wp-post"
          data-elementor-id="734"
          class="elementor elementor-734"
        >
          <Banner />

          <div
            class="elementor-element elementor-element-46c983f e-flex e-con-boxed  e-con e-parent"
            data-id="46c983f"
            data-element_type="container"
            data-settings='{"background_background":"classic","animation":"fadeIn"}'
          >
            <div class="e-con-inner">
              <div
                class="elementor-element elementor-element-8b24df7 e-con-full e-flex e-con e-child"
                data-id="8b24df7"
                data-element_type="container"
              >
                <div
                  class="elementor-element elementor-element-24755dd elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                  data-id="24755dd"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="icon-list.default"
                >
                  <div class="elementor-widget-container">
                    <ul class="elementor-icon-list-items elementor-inline-items">
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">
                          Data Driven
                        </span>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">Innovative</span>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">Creative</span>
                      </li>
                      <li class="elementor-icon-list-item elementor-inline-item">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="elementor-icon-list-text">ROI Driven</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section
            class="elementor-section elementor-top-section elementor-element  elementor-section-boxed elementor-section-height-default elementor-section-height-default "
            data-id="8acb873"
            data-element_type="section"
            data-settings='{"background_background":"classic","animation":"fadeIn"}'
            style={{ backgroundColor: "white" }}
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cf861f6"
                data-id="cf861f6"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-ba6b57f elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="ba6b57f"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items elementor-inline-items">
                        <li
                          class="elementor-icon-list-item elementor-inline-item"
                          style={{ margin: "auto" }}
                        >
                          <span class="elementor-icon-list-text">
                            <img src={trust2} className="sponsor-logo" />
                          </span>
                        </li>

                        <li
                          class="elementor-icon-list-item elementor-inline-item"
                          style={{ margin: "auto" }}
                        >
                          <span class="elementor-icon-list-text">
                            {" "}
                            <img src={trust} className="sponsor-logo" />
                          </span>
                        </li>
                        <li
                          class="elementor-icon-list-item elementor-inline-item"
                          style={{ margin: "auto" }}
                        >
                          <span class="elementor-icon-list-text">
                            {" "}
                            <img src={trust3} className="sponsor-logo" />
                          </span>
                        </li>
                        <li
                          class="elementor-icon-list-item elementor-inline-item"
                          style={{ margin: "auto" }}
                        >
                          <span class="elementor-icon-list-text">
                            {" "}
                            <img src={trust4} className="sponsor-logo" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            class="elementor-element elementor-element-afcaa7b e-flex e-con-boxed e-con e-parent"
            data-id="afcaa7b"
            data-element_type="container"
          >
            <div class="e-con-inner">
              <div
                class="elementor-element elementor-element-07478a9 e-con-full e-flex e-con e-child"
                data-id="07478a9"
                data-element_type="container"
              >
                <div
                  class="elementor-element elementor-element-f728bcb e-con-full e-flex e-con e-child"
                  data-id="f728bcb"
                  data-element_type="container"
                >
                  <div
                    class="elementor-element elementor-element-3fd2c59 e-con-full e-flex e-con e-child"
                    data-id="3fd2c59"
                    data-element_type="container"
                  >
                    <div
                      class="elementor-element elementor-element-578edf3  elementor-widget elementor-widget-heading"
                      data-id="578edf3"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h2 class="elementor-heading-title elementor-size-default">
                          Our Services
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-75865a3 e-con-full e-flex e-con e-child"
                    data-id="75865a3"
                    data-element_type="container"
                  >
                    <div
                      class="elementor-element elementor-element-67aeee8  elementor-widget elementor-widget-text-editor"
                      data-id="67aeee8"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        <p>
                          Explore ADZENON’s complete suite of services, all
                          aimed at enhancing your digital presence and impact.
                        </p>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-96e1810 elementor-align-left  elementor-widget elementor-widget-button"
                      data-id="96e1810"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="button.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-button-wrapper">
                          <a
                            class="elementor-button elementor-button-link elementor-size-sm"
                            href="#"
                          >
                            <span class="elementor-button-content-wrapper">
                              <span class="elementor-button-icon">
                                <i
                                  aria-hidden="true"
                                  class="fas fa-arrow-right"
                                ></i>
                              </span>
                              <span class="elementor-button-text">
                                All services
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-e8350a2 e-con-full e-flex e-con e-child"
                  data-id="e8350a2"
                  data-element_type="container"
                >
                  <div
                    class="elementor-element elementor-element-13f8d92 e-con-full e-flex e-con e-child"
                    data-id="13f8d92"
                    data-element_type="container"
                  >
                    <div
                      class="elementor-element elementor-element-a918e8a elementor-view-default elementor-position-top elementor-mobile-position-top  elementor-widget elementor-widget-icon-box"
                      data-id="a918e8a"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="icon-box.default"
                    >
                      <div class="elementor-widget-container">
                        <link
                          rel="stylesheet"
                          href="https://demo.sociolib.com/keen/wp-content/uploads/sites/22/elementor/css/custom-widget-icon-box.min.css?ver=1723171763"
                        />
                        <div class="elementor-icon-box-wrapper">
                          <div class="elementor-icon-box-icon">
                            <span class="elementor-icon elementor-animation-grow">
                              <i
                                aria-hidden="true"
                                class="icon icon-laptop-phone"
                              ></i>
                            </span>
                          </div>

                          <div class="elementor-icon-box-content">
                            <h5 class="elementor-icon-box-title">
                              <span>Sustainable Ecommerce development </span>
                            </h5>

                            <p class="elementor-icon-box-description">
                              We craft eco-friendly e-commerce sites and
                              software solutions using cutting-edge tech.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-327437c e-con-full e-flex e-con e-child"
                    data-id="327437c"
                    data-element_type="container"
                  >
                    <div
                      class="elementor-element elementor-element-532aee3 elementor-view-default elementor-position-top elementor-mobile-position-top  elementor-widget elementor-widget-icon-box"
                      data-id="532aee3"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp","_animation_delay":"250"}'
                      data-widget_type="icon-box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-icon-box-wrapper">
                          <div class="elementor-icon-box-icon">
                            <span class="elementor-icon elementor-animation-grow">
                              <i
                                aria-hidden="true"
                                class="icon icon-advanced-slider"
                              ></i>
                            </span>
                          </div>

                          <div class="elementor-icon-box-content">
                            <h5 class="elementor-icon-box-title">
                              <span>User-Generated Content + Paid Social </span>
                            </h5>
                            <p class="elementor-icon-box-description">
                              Elevate your brand through engaging content and
                              targeted social ads.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-dbcf3b9 e-con-full e-flex e-con e-child"
                    data-id="dbcf3b9"
                    data-element_type="container"
                  >
                    <div
                      class="elementor-element elementor-element-86e20aa elementor-view-default elementor-position-top elementor-mobile-position-top  elementor-widget elementor-widget-icon-box"
                      data-id="86e20aa"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp","_animation_delay":"500"}'
                      data-widget_type="icon-box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-icon-box-wrapper">
                          <div class="elementor-icon-box-icon">
                            <span class="elementor-icon elementor-animation-grow">
                              <i
                                aria-hidden="true"
                                class="icon icon-layers"
                              ></i>
                            </span>
                          </div>

                          <div class="elementor-icon-box-content">
                            <h5 class="elementor-icon-box-title">
                              <span>
                                Search Engine Marketing + Search Engine
                                Optimisation{" "}
                              </span>
                            </h5>

                            <p class="elementor-icon-box-description">
                              Boost online visibility with our SEM and SEO
                              expertise.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="elementor-element elementor-element-503efce e-flex e-con-boxed  e-con e-parent"
            data-id="503efce"
            data-element_type="container"
            data-settings='{"background_background":"classic","animation":"fadeIn"}'
          >
            <div class="e-con-inner">
              <div
                class="elementor-element elementor-element-4c1b0d7 e-con-full e-flex e-con e-child"
                data-id="4c1b0d7"
                data-element_type="container"
              >
                <div
                  class="elementor-element elementor-element-c2f557b  elementor-widget elementor-widget-heading"
                  data-id="c2f557b"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h5 class="elementor-heading-title elementor-size-default">
                      We're not just another agency. We're disruptors with a
                      mission
                    </h5>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-fe28e0e  elementor-widget elementor-widget-text-editor"
                  data-id="fe28e0e"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      In a world that often prioritises quantity over quality,
                      we're rewriting the narrative. We believe in creating a
                      balance between profit and purpose. Our small agency is
                      dreaming big, and we're inviting you to join us.
                      <br></br>
                      <br></br> We're architects of change, shaping a
                      regenerative economy where impact-driven entrepreneurs
                      lead the way. Ego takes a back seat as we forge a
                      compassionate, creative, and engaging path forward.
                      <br></br> <br></br>This is ADZENON. Ready to be part of
                      our extraordinary journey?
                    </p>{" "}
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      className="elementor-button elementor-button-link elementor-size-sm"
                      href="/contact"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-right">
                          <FaArrowRight style={{ color: "black" }} />
                        </span>
                        <span
                          className="elementor-button-text"
                          style={{ color: "black" }}
                        >
                          Join our journey
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-6c5b0cb e-con-full e-flex e-con e-child"
                data-id="6c5b0cb"
                data-element_type="container"
              >
                <div
                  class="elementor-element elementor-element-ed4334e  elementor-widget elementor-widget-image"
                  data-id="ed4334e"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      src={one}
                      decoding="async"
                      width="750"
                      height="600"
                      class="attachment-full size-full wp-image-167"
                      alt=""
                      loading="lazy"
                      srcset={one}
                      sizes="(max-width: 750px) 100vw, 750px"
                    />{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-f3d8f0e  elementor-widget elementor-widget-image"
                  data-id="f3d8f0e"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInRight"}'
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      decoding="async"
                      width="600"
                      height="750"
                      src={two}
                      class="attachment-full size-full wp-image-165"
                      alt=""
                      loading="lazy"
                      srcset={two}
                      sizes="(max-width: 600px) 100vw, 600px"
                    />{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-c2a8fbb  elementor-widget elementor-widget-image"
                  data-id="c2a8fbb"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      decoding="async"
                      width="1200"
                      height="800"
                      src={three}
                      class="attachment-full size-full wp-image-262"
                      alt=""
                      loading="lazy"
                      srcset={three}
                      sizes="(max-width: 1200px) 100vw, 1200px"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DuotoneSVG />
          <Rev />
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-78b2688 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="78b2688"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-25ffb51"
                data-id="25ffb51"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-1eb869d e-flex e-con-boxed e-con e-parent"
                    data-id="1eb869d"
                    data-element_type="container"
                  >
                    <div class="e-con-inner">
                      <div
                        class="elementor-element elementor-element-48b755f e-con-full e-flex e-con e-child"
                        data-id="48b755f"
                        data-element_type="container"
                      >
                        <div
                          class="elementor-element elementor-element-2e29baf e-con-full e-flex e-con e-child"
                          data-id="2e29baf"
                          data-element_type="container"
                        >
                          <div
                            class="elementor-element elementor-element-048992c e-con-full e-flex e-con e-child"
                            data-id="048992c"
                            data-element_type="container"
                          >
                            <div
                              class="elementor-element elementor-element-bc3bb52  elementor-widget elementor-widget-heading"
                              data-id="bc3bb52"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp"}'
                              data-widget_type="heading.default"
                            >
                              <div class="elementor-widget-container">
                                <h5 class="elementor-heading-title elementor-size-default">
                                  Our Highest Performing Creatives
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-element elementor-element-a0956c3 e-con-full e-flex e-con e-child"
                            data-id="a0956c3"
                            data-element_type="container"
                          >
                            <div
                              class="elementor-element elementor-element-dbcd3d8  elementor-widget elementor-widget-text-editor"
                              data-id="dbcd3d8"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeInUp"}'
                              data-widget_type="text-editor.default"
                            >
                              <div class="elementor-widget-container">
                                <p>
                                  A showcase of some of our highest performing
                                  user-generated content (UGC) made in
                                  collaboration with our creative partner.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Swipe />
                </div>
              </div>
            </div>
          </section>
          <Tag />
          <div
            class="elementor-element elementor-element-ab9928d e-flex e-con-boxed e-con e-parent"
            data-id="ab9928d"
            data-element_type="container"
          >
            <div class="e-con-inner">
              <div
                class="elementor-element elementor-element-930b92c e-con-full e-flex  e-con e-child"
                data-id="930b92c"
                data-element_type="container"
                data-settings='{"background_background":"classic","animation":"fadeIn"}'
              >
                <div
                  class="elementor-element elementor-element-036f287 elementor-widget__width-initial elementor-widget-mobile__width-inherit  elementor-widget elementor-widget-heading"
                  data-id="036f287"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h3 class="elementor-heading-title elementor-size-default">
                      Ready to Get Started?
                    </h3>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-9aea8c1 elementor-align-right elementor-widget__width-initial elementor-mobile-align-left elementor-widget-mobile__width-inherit  elementor-widget elementor-widget-button"
                  data-id="9aea8c1"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp","_animation_delay":"250"}'
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <a
                        class="elementor-button elementor-button-link elementor-size-sm"
                        href="#"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-icon"></span>
                          <span
                            class="elementor-button-text"
                            style={{ color: "black" }}
                          >
                            Contact Us
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </>
  );
};

export default Home;
