import React from "react";
import Footer from "./Footer";
import Okay from "./Okay";
import "./Ele.css";
import three from "./image/1.JPG";
import "font-awesome/css/font-awesome.min.css";

const Tag = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="431"
        class="elementor elementor-431"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-1d7a460 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="1d7a460"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7dec54"
              data-id="f7dec54"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-b9b1a20  elementor-widget elementor-widget-heading"
                  data-id="b9b1a20"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      Frequently asked question
                    </h4>{" "}
                  </div>
                </div>

                <div
                  class="elementor-element elementor-element-ba9fb2e  elementor-widget elementor-widget-accordion"
                  data-id="ba9fb2e"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="accordion.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-accordion">
                      <div class="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-1951"
                          class="elementor-tab-title"
                          data-tab="1"
                          role="button"
                          aria-controls="elementor-tab-content-1951"
                          aria-expanded="false"
                        >
                          <span
                            class="elementor-accordion-icon elementor-accordion-icon-right"
                            aria-hidden="true"
                          >
                            <span class="elementor-accordion-icon-closed">
                              <i class="fa fa-plus"></i>
                            </span>
                            <span class="elementor-accordion-icon-opened">
                              <i class="fa fa-minus"></i>
                            </span>
                          </span>
                          <a class="elementor-accordion-title" tabindex="0">
                            What is our company primary mission?
                          </a>
                        </div>
                        <div
                          id="elementor-tab-content-1951"
                          class="elementor-tab-content elementor-clearfix"
                          data-tab="1"
                          role="region"
                          aria-labelledby="elementor-tab-title-1951"
                        >
                          Our key goal is to amplify sustainable brands, helping
                          them shine in the crowded digital landscape.
                        </div>
                      </div>
                      <div class="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-1952"
                          class="elementor-tab-title"
                          data-tab="2"
                          role="button"
                          aria-controls="elementor-tab-content-1952"
                          aria-expanded="false"
                        >
                          <span
                            class="elementor-accordion-icon elementor-accordion-icon-right"
                            aria-hidden="true"
                          >
                            <span class="elementor-accordion-icon-closed">
                              <i class="fa fa-plus"></i>
                            </span>
                            <span class="elementor-accordion-icon-opened">
                              <i class="fa fa-minus"></i>
                            </span>
                          </span>
                          <a class="elementor-accordion-title" tabindex="0">
                            What is user-generated content?
                          </a>
                        </div>
                        <div
                          id="elementor-tab-content-1952"
                          class="elementor-tab-content elementor-clearfix"
                          data-tab="2"
                          role="region"
                          aria-labelledby="elementor-tab-title-1952"
                        >
                          User-generated content refers to any content, from
                          text to videos, created by individuals rather than
                          brands, which we use to enhance engagement and trust.
                        </div>
                      </div>
                      <div class="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-1952"
                          class="elementor-tab-title"
                          data-tab="3"
                          role="button"
                          aria-controls="elementor-tab-content-1952"
                          aria-expanded="false"
                        >
                          <span
                            class="elementor-accordion-icon elementor-accordion-icon-right"
                            aria-hidden="true"
                          >
                            <span class="elementor-accordion-icon-closed">
                              <i class="fa fa-plus"></i>
                            </span>
                            <span class="elementor-accordion-icon-opened">
                              <i class="fa fa-minus"></i>
                            </span>
                          </span>
                          <a class="elementor-accordion-title" tabindex="0">
                            Is ADZENON sustainable?
                          </a>
                        </div>
                        <div
                          id="elementor-tab-content-1952"
                          class="elementor-tab-content elementor-clearfix"
                          data-tab="3"
                          role="region"
                          aria-labelledby="elementor-tab-title-1952"
                        >
                          ADZENON is more than just a company - it is a
                          movement. We are passionate about creating a
                          regenerative economy and making sustainability the
                          norm
                        </div>
                      </div>
                      <div class="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-1952"
                          class="elementor-tab-title"
                          data-tab="4"
                          role="button"
                          aria-controls="elementor-tab-content-1952"
                          aria-expanded="false"
                        >
                          <span
                            class="elementor-accordion-icon elementor-accordion-icon-right"
                            aria-hidden="true"
                          >
                            <span class="elementor-accordion-icon-closed">
                              <i class="fa fa-plus"></i>
                            </span>
                            <span class="elementor-accordion-icon-opened">
                              <i class="fa fa-minus"></i>
                            </span>
                          </span>
                          <a class="elementor-accordion-title" tabindex="0">
                            What is your guarantee?
                          </a>
                        </div>
                        <div
                          id="elementor-tab-content-1952"
                          class="elementor-tab-content elementor-clearfix"
                          data-tab="4"
                          role="region"
                          aria-labelledby="elementor-tab-title-1952"
                        >
                          We’ll outperform your current UGC ads using TikTok/
                          Meta + our creatives. If we don’t, we’ll keep pushing,
                          at no extra cost, until we do
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3b7a97e "
              data-id="3b7a97e"
              data-element_type="column"
              data-settings='{"background_background":"classic","animation":"fadeIn"}'
            >
              <img src={three} />{" "}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Tag;
