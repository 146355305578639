// ParentTable.js
import React, { useEffect } from "react";
import "./Style.css";
import hope from "./image/new5.png";
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { FaArrowRight, FaCheck } from "react-icons/fa";
const Banner = () => {
  useEffect(() => {
    const logo = document.querySelector(".logo-img");

    const bounceLogo = () => {
      gsap.to(logo, {
        y: -20, // Adjust the bounce height
        duration: 0.5,
        repeat: -1, // Infinite repeat
        yoyo: true, // Bounce back and forth
        ease: "power1.inOut",
      });
    };

    bounceLogo();
  }, []);

  return (
    <div>
      <div
        class="elementor-element elementor-element-cbe1131 e-flex e-con-boxed  e-con e-parent"
        data-id="cbe1131"
        data-element_type="container"
        data-settings='{"background_background":"classic","animation":"fadeIn","shape_divider_bottom":"opacity-fan"}'
      >
        <div class="e-con-inner">
          <div
            class="elementor-shape elementor-shape-bottom"
            data-negative="false"
          ></div>
          <div
            class="elementor-element elementor-element-f1be5c9 e-con-full e-flex e-con e-child"
            data-id="f1be5c9"
            data-element_type="container"
          >
            <div
              class="elementor-element elementor-element-c59965f  elementor-widget elementor-widget-heading"
              data-id="c59965f"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInUp"}'
              data-widget_type="heading.default"
            >
              <div class="elementor-widget-container">
                <img
                  src={hope}
                  alt="ADZENON Logo"
                  style={{
                    width: "400px",
                    height: "auto",
                    marginBottom: "0",
                  }}
                  className="logo-img"
                />
              </div>
            </div>
            <div
              class="elementor-element elementor-element-7439aa8  elementor-widget elementor-widget-text-editor"
              data-id="7439aa8"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInUp"}'
              data-widget_type="text-editor.default"
            >
              <div class="elementor-widget-container">
                <p>
                  <Typewriter
                    options={{
                      strings: [
                        "    We are ADZENON. A digital marketing powerhouse on a mission to help supercharge sustainable brands and break through the noise.",
                      ],
                      autoStart: true,
                      loop: true,
                      delay: 40,
                      deleteSpeed: 40,
                      typeSpeed: 40,
                    }}
                  />
                </p>{" "}
              </div>
            </div>
            <div
              class="elementor-element elementor-element-cf7230f elementor-align-center  elementor-widget elementor-widget-button"
              data-id="cf7230f"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInUp"}'
              data-widget_type="button.default"
            >
              <div class="elementor-widget-container">
                <div class="elementor-button-wrapper">
                  <a
                    class="elementor-button elementor-button-link elementor-size-sm"
                    href="#"
                  >
                    <span class="elementor-button-content-wrapper">
                      <span class="elementor-button-icon">
                        {/*}    <i
                        aria-hidden="true"
                        class="fas fa-arrow-right"
                        style={{ color: "white" }}
                      ></i>*/}
                      </span>
                      <span
                        class="elementor-button-text"
                        style={{ color: "black" }}
                      >
                        {" "}
                        Let's Talk
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
