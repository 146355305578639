import React from "react";
import "./Style.css";
import one from "./image/7.JPG";
import two from "./image/8.JPG";
import { FaStar, FaQuoteRight } from "react-icons/fa";
const Rev = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="552"
        class="elementor elementor-552"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-4e7916a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="4e7916a"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8463894"
              data-id="8463894"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-842a369  elementor-widget elementor-widget-image"
                  data-id="842a369"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInLeft"}'
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      decoding="async"
                      width="700"
                      height="700"
                      style={{ width: "700px", height: "700px" }}
                      src={one}
                      alt=""
                      loading="lazy"
                      srcset={one}
                      sizes="(max-width: 700px) 70vw, 700px"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5ef856b"
              data-id="5ef856b"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-743e755  elementor-widget elementor-widget-heading"
                  data-id="743e755"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInRight"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Reviews
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-51edcef  elementor-widget elementor-widget-elementskit-testimonial"
                  data-id="51edcef"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="elementskit-testimonial.default"
                >
                  <div class="elementor-widget-container">
                    <div class="ekit-wid-con">
                      <div
                        class="elementskit-testimonial-slider ekit_testimonial_style_5 arrow_inside "
                        data-config='{"rtl":false,"arrows":true,"dots":false,"pauseOnHover":false,"autoplay":true,"speed":1000,"slidesPerGroup":2,"slidesPerView":1,"loop":true,"breakpoints":{"320":{"slidesPerView":1,"slidesPerGroup":1,"spaceBetween":10},"768":{"slidesPerView":2,"slidesPerGroup":1,"spaceBetween":10},"1024":{"slidesPerView":1,"slidesPerGroup":2,"spaceBetween":15}}}'
                      >
                        <div class="ekit-main-swiper swiper-container">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              <div class="swiper-slide-inner">
                                <div class="elementskit-single-testimonial-slider elementskit-testimonial-slider-block-style elementskit-testimonial-slider-block-style-two   elementor-repeater-item-29f88f9">
                                  <div class="elementskit-commentor-header">
                                    <ul class="elementskit-stars">
                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="elementskit-commentor-content">
                                    <p>
                                      "The content you never knew you wanted but
                                      knew you always needed."
                                    </p>
                                  </div>

                                  <div class="elementskit-commentor-bio">
                                    <div class="elementkit-commentor-details ">
                                      <div class="elementskit-commentor-image ekit-testimonial--avatar"></div>
                                      <div class="elementskit-profile-info">
                                        <strong class="elementskit-author-name">
                                          Energy Byte
                                        </strong>
                                      </div>
                                    </div>
                                    <div class="elementskit-icon-content elementskit-watermark-icon ">
                                      <FaQuoteRight />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="swiper-slide">
                              <div class="swiper-slide-inner">
                                <div class="elementskit-single-testimonial-slider elementskit-testimonial-slider-block-style elementskit-testimonial-slider-block-style-two   elementor-repeater-item-b8cd21a">
                                  <div class="elementskit-commentor-header">
                                    <ul class="elementskit-stars">
                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>

                                      <li>
                                        <a>
                                          <FaStar style={{ color: "yellow" }} />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="elementskit-commentor-content">
                                    <p>
                                      "What they produced was way above what we
                                      asked for."
                                    </p>
                                  </div>

                                  <div class="elementskit-commentor-bio">
                                    <div class="elementkit-commentor-details ">
                                      <div class="elementskit-commentor-image ekit-testimonial--avatar"></div>
                                      <div class="elementskit-profile-info">
                                        <strong class="elementskit-author-name">
                                          Its Milk co
                                        </strong>
                                      </div>
                                    </div>
                                    <div class="elementskit-icon-content elementskit-watermark-icon ">
                                      <FaQuoteRight />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Rev;
