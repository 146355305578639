import React from "react";
import DuotoneSvG from "./DuotoneSVG";
import "./Style.css";
import { FaArrowRight } from "react-icons/fa";
function Okay() {
  return (
    <div
      data-elementor-type="wp-post"
      data-elementor-id="734"
      class="elementor elementor-734"
    >
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-6353b13 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="6353b13"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5186abc "
            data-id="5186abc"
            data-element_type="column"
            data-settings='{"background_background":"classic","animation":"fadeIn"}'
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-background-overlay"></div>
              <div
                class="elementor-element elementor-element-0683266 elementor-widget__width-initial elementor-widget-mobile__width-inherit  elementor-widget elementor-widget-heading"
                data-id="0683266"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp"}'
                data-widget_type="heading.default"
              >
                <div class="elementor-widget-container">
                  <h3 class="elementor-heading-title elementor-size-default">
                    Ready to Get Started?
                  </h3>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-f893a95 elementor-align-right elementor-widget__width-initial elementor-mobile-align-left elementor-widget-mobile__width-inherit  elementor-widget elementor-widget-button"
                data-id="f893a95"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                data-widget_type="button.default"
              >
                <div class="elementor-widget-container">
                  <div class="elementor-button-wrapper">
                    <a
                      class="elementor-button elementor-button-link elementor-size-sm"
                      href="#"
                    >
                      <span class="elementor-button-content-wrapper">
                        <span class="elementor-button-icon elementor-align-icon-right">
                          <FaArrowRight />
                        </span>
                        <span class="elementor-button-text">Book a call</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Okay;
