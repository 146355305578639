import React from "react";
import three from "./image/new5.png";
import "./Style.css";
import {
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaEnvelope,
  FaPhone,
  FaArrowUp,
} from "react-icons/fa";
const Footer = () => {
  // const openGreenGeeksSeal = () => {
  //   window.open("https://adzenon.com/", "_blank");
  // };
  return (
    <div>
      <div class="ekit-template-content-markup ekit-template-content-footer ekit-template-content-theme-support">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="30"
          class="elementor elementor-30"
        >
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-3568c2a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="3568c2a"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-66dba98"
                data-id="66dba98"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-d0db350  elementor-widget elementor-widget-image"
                    data-id="d0db350"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <a href="#">
                        <img src={three} />{" "}
                      </a>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-c9bf48f  elementor-widget elementor-widget-text-editor"
                    data-id="c9bf48f"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <p>
                        Where marketing meets sustainability. We power brands
                        with purpose for a regenerative future.
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-419efeb elementor-shape-square e-grid-align-left elementor-grid-0  elementor-widget elementor-widget-social-icons"
                    data-id="419efeb"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="social-icons.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-social-icons-wrapper elementor-grid">
                        <span class="elementor-grid-item">
                          <a
                            class="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-dd13b7a"
                            target="_blank"
                            href="https://instagram.com/adzenonagency/"
                          >
                            <span class="elementor-screen-only">Instagram</span>
                            <FaInstagram />
                          </a>
                        </span>
                        <span class="elementor-grid-item">
                          <a
                            class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-fdae43c"
                            target="_blank"
                            href="https://www.linkedin.com/company/lightwave-creative-agency/"
                          >
                            <span class="elementor-screen-only">Linkedin</span>
                            <FaLinkedin />
                          </a>
                        </span>
                        <span class="elementor-grid-item">
                          <a
                            class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-repeater-item-23e3580"
                            target="_blank"
                          >
                            <span class="elementor-screen-only">Youtube</span>
                            <FaYoutube />
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-c2b8be9"
                data-id="c2b8be9"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-cd79349  elementor-widget elementor-widget-heading"
                    data-id="cd79349"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h6 class="elementor-heading-title elementor-size-default">
                        Office
                      </h6>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-09f2a40  elementor-widget elementor-widget-text-editor"
                    data-id="09f2a40"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <p>
                        2nd Floor, College House, 17 King Edwards Road, Ruislip,
                        London, United Kingdom HA4 7AE
                      </p>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-628e8f4 elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                    data-id="628e8f4"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <a href="#">
                            <span class="elementor-icon-list-icon">
                              <FaEnvelope />
                            </span>
                            <span class="elementor-icon-list-text">
                              <span
                                class="__cf_email__"
                                data-cfemail="9ff2fef6f3dff4fafaf1b1fcf0"
                              >
                                info@adzenon.com
                              </span>
                            </span>
                          </a>
                        </li>
                        <li class="elementor-icon-list-item">
                          <a href="#">
                            <span class="elementor-icon-list-icon">
                              <FaPhone />
                            </span>
                            <span class="elementor-icon-list-text">
                              (+44 7) 311 334436
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-124122f"
                data-id="124122f"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-d3cf074  elementor-widget elementor-widget-heading"
                    data-id="d3cf074"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h6 class="elementor-heading-title elementor-size-default">
                        Menu
                      </h6>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-a4b41ac elementor-widget-mobile__width-inherit elementor-icon-list--layout-traditional elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                    data-id="a4b41ac"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <a href="/about">
                            <span class="elementor-icon-list-text">About</span>
                          </a>
                        </li>
                        <li class="elementor-icon-list-item">
                          <a href="/service">
                            <span class="elementor-icon-list-text">
                              Services
                            </span>
                          </a>
                        </li>
                        <li class="elementor-icon-list-item">
                          <a href="/blog">
                            <span class="elementor-icon-list-text">Blog</span>
                          </a>
                        </li>
                        <li class="elementor-icon-list-item">
                          <a href="/contact">
                            <span class="elementor-icon-list-text">
                              Contact
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-45b16e6"
                data-id="45b16e6"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-60d6f10  elementor-widget elementor-widget-heading"
                    data-id="60d6f10"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h6 class="elementor-heading-title elementor-size-default">
                        Links
                      </h6>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-cac29dd elementor-widget-mobile__width-inherit elementor-icon-list--layout-traditional elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                    data-id="cac29dd"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <a href="/about">
                            <span class="elementor-icon-list-text">
                              About Us
                            </span>
                          </a>
                        </li>
                        <li class="elementor-icon-list-item">
                          <a href="/contact">
                            <span class="elementor-icon-list-text">
                              Contact
                            </span>
                          </a>
                        </li>
                        <li class="elementor-icon-list-item">
                          <a href="/">
                            <span class="elementor-icon-list-text">Faq</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-f68c0e9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="f68c0e9"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c8eebbd"
                data-id="c8eebbd"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-8142377 elementor-widget-divider--view-line  elementor-widget elementor-widget-divider"
                    data-id="8142377"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeIn"}'
                    data-widget_type="divider.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-divider">
                        <span class="elementor-divider-separator"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-89c62e4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="89c62e4"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-746f5ba"
                data-id="746f5ba"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-f573c01  elementor-widget elementor-widget-text-editor"
                    data-id="f573c01"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <p>
                        Powered by <a href="https://www.adzenon.com">ADZENON</a>
                        .
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-53274fa"
                data-id="53274fa"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-be45083 elementor-widget-mobile__width-inherit elementor-icon-list--layout-inline elementor-align-right elementor-mobile-align-left elementor-list-item-link-full_width  elementor-widget elementor-widget-icon-list"
                    data-id="be45083"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInUp"}'
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items elementor-inline-items">
                        <li class="elementor-icon-list-item elementor-inline-item">
                          <a href="/">
                            <span class="elementor-icon-list-text">
                              Privacy Policy
                            </span>
                          </a>
                        </li>
                        <li class="elementor-icon-list-item elementor-inline-item">
                          <a href="/">
                            <span class="elementor-icon-list-text">
                              Our Terms
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-55e6d00 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="55e6d00"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3e777d"
                data-id="b3e777d"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-ccbcd05 elementor-widget__width-initial elementor-fixed elementor-widget elementor-widget-elementskit-back-to-top"
                    data-id="ccbcd05"
                    data-element_type="widget"
                    data-settings='{"_position":"fixed"}'
                    data-widget_type="elementskit-back-to-top.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="ekit-wid-con">
                        {" "}
                        <div
                          class="ekit-back-to-top-container ekit-btt progress_indicator"
                          data-settings='{"offset_top":"","show_after":400,"show_scroll":"yes","style":"progress_indicator","foreground":"#C4FB6D","background":"#767676"}'
                        >
                          <span class="ekit-btt__button yes">
                            <div class="progress_indicator">
                              <canvas
                                id="canvas-ccbcd05"
                                class="canvas"
                                data-canvas="ccbcd05"
                              ></canvas>
                              <span>
                                <FaArrowUp class="fas fa-arrow-up" />
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <div>
            <a href="#" onClick={openGreenGeeksSeal}>
              <img
                src={three}
                alt="Adzenon"
                style={{ width: "auto", height: "50px" }}
              />
            </a>
  </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Footer;
