import React from "react";
import DuotoneSvG from "./DuotoneSVG";
import "./Style.css";

import { CiMenuFries } from "react-icons/ci";

const Navv = () => {
  return (
    <>
      <div class="ekit-template-content-markup ekit-template-content-header ekit-template-content-theme-support envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="26"
          class="elementor elementor-26"
        >
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-18e148b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="18e148b"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4bb98c1"
                data-id="4bb98c1"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-44617a2 elementor-widget elementor-widget-image"
                    data-id="44617a2"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInDown"}'
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <a href="/" style={{ display: "flex" }}>
                        <img
                          src="./image/new5.png"
                          alt=""
                          loading="lazy"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c070eb9"
                data-id="c070eb9"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated envato_tk_templates-template envato_tk_templates-template-elementor_header_footer single single-envato_tk_templates postid-734 elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-734">
                  <div
                    class="elementor-element elementor-element-7290a1e elementor-widget elementor-widget-ekit-nav-menu"
                    data-id="7290a1e"
                    data-element_type="widget"
                    data-settings='{"_animation":"fadeInDown"}'
                    data-widget_type="ekit-nav-menu.default"
                  >
                    <div class="elementor-widget-container">
                      <div
                        class="ekit-wid-con ekit_menu_responsive_tablet"
                        data-hamburger-icon="icon icon-menu-11"
                        data-hamburger-icon-type="icon"
                        data-responsive-breakpoint="1024"
                      >
                        <button
                          class="elementskit-menu-hamburger elementskit-menu-toggler"
                          type="button"
                          aria-label="hamburger-icon"
                        >
                          <CiMenuFries class="ekit-menu-icon icon icon-menu-11" />
                        </button>
                        <div
                          id="ekit-megamenu-menu"
                          class="elementskit-menu-container elementskit-menu-offcanvas-elements elementskit-navbar-nav-default elementskit_line_arrow ekit-nav-menu-one-page- ekit-nav-dropdown-hover"
                        >
                          <ul
                            id="menu-menu"
                            class="elementskit-navbar-nav elementskit-menu-po-right submenu-click-on-icon"
                          >
                            <li
                              id="menu-item-78"
                              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-78 nav-item elementskit-mobile-builder-content"
                              data-vertical-menu="750px"
                            >
                              <a href="/" class="ekit-menu-nav-link">
                                Home
                              </a>
                            </li>
                            <li
                              id="menu-item-78"
                              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-78 nav-item elementskit-mobile-builder-content"
                              data-vertical-menu="750px"
                            >
                              <a href="/about" class="ekit-menu-nav-link">
                                About
                              </a>
                            </li>

                            <li
                              id="menu-item-82"
                              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-82 nav-item elementskit-dropdown-has top_position elementskit-dropdown-menu-default_width elementskit-megamenu-has elementskit-mobile-builder-content"
                              data-vertical-menu="750px"
                            >
                              <a href="/service" class="ekit-menu-nav-link">
                                Services<i></i>
                              </a>
                            </li>

                            <li
                              id="menu-item-76"
                              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-76 nav-item elementskit-mobile-builder-content"
                              data-vertical-menu="750px"
                            >
                              <a href="/blogs" class="ekit-menu-nav-link">
                                Green Talks
                              </a>
                            </li>
                            <li
                              id="menu-item-78"
                              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-78 nav-item elementskit-mobile-builder-content"
                              data-vertical-menu="750px"
                            >
                              <a href="/contact" class="ekit-menu-nav-link">
                                Book a Call
                              </a>
                            </li>
                          </ul>
                          <div class="elementskit-nav-identity-panel">
                            <div class="elementskit-site-title">
                              <a
                                class="elementskit-nav-logo"
                                href="/"
                                target="_self"
                                rel=""
                              >
                                <img
                                  width="244"
                                  height="47"
                                  src="./image/new5.png"
                                  class="attachment-full size-full"
                                  alt=""
                                  decoding="async"
                                  loading="lazy"
                                />
                              </a>
                            </div>
                            <button
                              class="elementskit-menu-close elementskit-menu-toggler"
                              type="button"
                            >
                              X
                            </button>
                          </div>
                        </div>
                        <div class="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler ekit-nav-menu--overlay"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Navv;
