import React from "react";
import Footer from "./Footer";
import Okay from "./Okay";
import image from "./image/copy.jpg";
import image1 from "./image/ADZENON_Orange Icon.png";
import image2 from "./image/aus.jpg";
import "./font.css";
import { Link } from "react-router-dom";
const Ebook = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-post"
        data-elementor-id="401"
        class="elementor elementor-401"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-5574866 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default "
          data-id="5574866"
          data-element_type="section"
          data-settings='{"background_background":"classic","animation":"fadeIn"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-689ba33"
              data-id="689ba33"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-adb6532  elementor-widget elementor-widget-heading"
                  data-id="adb6532"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      E-book Download
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-bd58def  elementor-widget elementor-widget-text-editor"
                  data-id="bd58def"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p></p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        class="elementor-element elementor-element-adb6532  elementor-widget elementor-widget-heading"
        data-id="adb6532"
        data-element_type="widget"
        data-settings='{"_animation":"fadeInUp"}'
        data-widget_type="heading.default"
      >
        <div
          class="elementor-widget-container"
          style={{ margin: "auto", textAlign: "center", marginTop: "40px" }}
        >
          <h3 class="elementor-heading-title elementor-size-default">
            Our Free Ebook
          </h3>{" "}
          <div class="elementor-widget-container">
            <p>List of our free E-book to download</p>{" "}
          </div>
        </div>
      </div>
      <section
        class="elementor-section elementor-inner-section elementor-element elementor-element-f34efc7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="f34efc7"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9c839be"
            data-id="9c839be"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-305bbeb elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                data-id="305bbeb"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp"}'
                data-widget_type="icon-box.default"
              >
                <div class="elementor-widget-container">
                  <div class="elementor-icon-box-wrapper">
                    <div class="elementor-icon-box-icon">
                      <span class="elementor-icon elementor-animation-grow">
                        <img src={image2} className="height" />
                      </span>
                    </div>
                    <div class="elementor-icon-box-content">
                      <h5 class="elementor-icon-box-title">
                        <span>Sustainable E-commerce Development </span>
                      </h5>
                      <Link to="/ebooklogin">
                        {" "}
                        <button>Download</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-af4f3c0"
            data-id="af4f3c0"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-0b4fcd2 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                data-id="0b4fcd2"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp","_animation_delay":250}'
                data-widget_type="icon-box.default"
              >
                <div class="elementor-widget-container">
                  <div class="elementor-icon-box-wrapper">
                    <div class="elementor-icon-box-icon">
                      <span class="elementor-icon elementor-animation-grow">
                        <img src={image1} className="height" />
                      </span>
                    </div>
                    <div class="elementor-icon-box-content">
                      <h5 class="elementor-icon-box-title">
                        <span>User-Generated Content + Paid Social</span>
                      </h5>
                      <button>Download</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c4749ec"
            data-id="c4749ec"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-740f8b7 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                data-id="740f8b7"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp","_animation_delay":500}'
                data-widget_type="icon-box.default"
              >
                <div class="elementor-widget-container">
                  <div class="elementor-icon-box-wrapper">
                    <div class="elementor-icon-box-icon">
                      <span class="elementor-icon elementor-animation-grow">
                        <img src={image} className="height" />
                      </span>
                    </div>
                    <div class="elementor-icon-box-content">
                      <h5 class="elementor-icon-box-title">
                        <span>
                          Search engine marketing + Search engine optimization{" "}
                        </span>
                      </h5>
                      <button>Download</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Ebook;
