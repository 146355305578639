import { Table } from "./Components/Table";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./component/Home";
import About from "./component/About";
import Footer from "./component/Footer";
import Nav from "./component/Nav";
import New from "./component/New";
import Banner from "./component/Banner";
import DuotoneSVG from "./component/DuotoneSVG";
import Pricing from "./component/Pricing";
import Single from "./component/Single";
import Review from "./component/Review";
import Work from "./component/Work";
import Service from "./component/Service";
import Okay from "./component/Okay";
import Home3 from "./component/Home3";
import Contact from "./component/Contact";
import Book from "./component/Book";
import Tag from "./component/Tag";
import Blog from "./component/Blog";
import SingleBlog from "./component/SingleBlog";
import Swipe from "./component/Swipe";
import Ebook from "./component/Ebook";
import Ebooklogin from "./component/Ebooklogin";
import Land from "./component/Land";
import Claim from "./component/Claim";
import Free from "./component/Free";
import Wordblog from "./component/Wordblog";
import NewPage from "./NewPage";
import RedirectComponent from "./RedirectComponent";

function App() {
  if (window.location.hostname === "adzenon.com") {
    window.location.replace(
      "https://www.adzenon.com" + window.location.pathname
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/free" element={<Free />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/new" element={<New />} />
          <Route path="*" element={<Navigate to="https://www.adzenon.com" />} />
          <Route exact path="/banner" element={<Banner />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/review" element={<Review />} />
          <Route exact path="/single" element={<Single />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/ebook" element={<Ebook />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/work" element={<Work />} />
          <Route exact path="/duo" element={<DuotoneSVG />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/footer" element={<Footer />} />
          <Route exact path="/nav" element={<Nav />} />
          <Route path="/singleblog/:id" element={<SingleBlog />} />
          <Route exact path="/okay" element={<Okay />} />
          <Route exact path="/home3" element={<Home3 />} />
          <Route exact path="/tag" element={<Tag />} />
          <Route exact path="/book" element={<Book />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/" element={<Swipe />} />
          <Route exact path="/ebooklogin" element={<Ebooklogin />} />
          <Route exact path="/newpage" element={<Land />} />
          <Route exact path="/claim" element={<Claim />} />

          <Route exact path="/landing-page" element={<NewPage />} />
          <Route exact path="/blogs" element={<Wordblog />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
