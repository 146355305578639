import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import post2 from "./image/mat.png";
import post3 from "./image/4.png";

import post4 from "./image/six.png";
import post5 from "./image/buy.png";
import post6 from "./image/screen10.png";
import "./swipe.css";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "font-awesome/css/font-awesome.min.css";

// // Define video URLs
// const videoUrls = [
//   "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/let_sadcnr.mp4",
//   "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/mat_lyihem.mp4",
//   "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/4_bgqz8l.mp4",
//   "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/4_bgqz8l.mp4",
//   "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/4_bgqz8l.mp4",
//   "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/4_bgqz8l.mp4",
// ];

const videoUrls = [
  {
    videoUrl:
      "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/mat_lyihem.mp4",
    posterUrl: post2,
  },
  {
    videoUrl:
      "https://res.cloudinary.com/dftygokow/video/upload/v1683488753/4_bgqz8l.mp4",
    posterUrl: post3,
  },
  {
    videoUrl:
      "https://res.cloudinary.com/dftygokow/video/upload/v1683491919/six_jgvkaz.mp4",
    posterUrl: post4,
  },
  {
    videoUrl:
      "https://res.cloudinary.com/dftygokow/video/upload/v1683491996/buy_kjvvhz.mp4",
    posterUrl: post5,
  },
  {
    videoUrl:
      "https://res.cloudinary.com/dftygokow/video/upload/v1695294679/cloud_pbadwy.mp4",
    posterUrl: post6,
  },

  // Add more video and poster pairs as needed
];

function Swipe() {
  return (
    <div className="container">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        {videoUrls.map((data, index) => (
          <SwiperSlide key={index}>
            <video width="100%" controls poster={data.posterUrl}>
              <source src={data.videoUrl} type="video/mp4" />
            </video>
          </SwiperSlide>
        ))}
        <div className="slider-controler">
          <div
            className="swiper-button-prev slider-arrow"
            style={{ color: "black" }}
          >
            <i class="fas fa-arrow-left" style={{ color: "black" }}></i>
          </div>
          <div
            className="swiper-button-next slider-arrow"
            style={{ color: "black" }}
          >
            <i class="fas fa-arrow-right" style={{ color: "black" }}></i>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default Swipe;
